import { Outlet, useLocation } from 'react-router-dom'
import { DownloadIcon } from 'src/assets/icons/customIcons/Download'
import { NoPicture } from 'src/assets/icons/customIcons/noPicture'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'

export default function RegistrationLayout(): JSX.Element {
  const t = useTranslatable()
  const { pathname } = useLocation()
  const registrationType = pathname.includes('lecturers') ? 'lecturers' : 'masters'

  return (
    <Section
      title={
        registrationType === 'lecturers'
          ? t('registration:lecturers_registration')
          : t('registration:masters_registration')
      }
      icon={
        <div className="size-[40px]">
          <NoPicture />
        </div>
      }
      className="min-h-[calc(100vh_-_245px)]"
      rightElement={
        <a
          href={`https://storage.iliauni.edu.ge/staging-argus/registration/registration_instructions_${registrationType}.pdf`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex h-fit rounded-full p-2 text-primaryTextColor hover:bg-hover-icon"
        >
          <div className="mr-4 h-fit w-[18px]">
            <DownloadIcon />
          </div>
          {t('common:instructions_of_registration')}
        </a>
      }
    >
      <Outlet />
    </Section>
  )
}
