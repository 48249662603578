import { useFormikContext } from 'formik'
import React from 'react'
import Arrow from 'src/assets/icons/customIcons/BackIcon'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'

export function SubmitButton({
  setStep,
  step,
}: {
  step: string
  setStep: React.Dispatch<React.SetStateAction<string>>
}): React.ReactElement {
  const t = useTranslatable()
  const formik = useFormikContext()

  const handleClick = React.useCallback(() => {
    void formik.validateForm().then(async (errors) => {
      // eslint-disable-next-line no-prototype-builtins
      const isValid = !errors.hasOwnProperty(step)

      const errorsObj: { [key: string]: boolean } = { ...errors }
      if (!isValid) {
        Object.keys(errorsObj).forEach((errorStep) => {
          if (errorStep !== step) {
            errorsObj[errorStep] = false
          }
        })
        void formik.setTouched(errorsObj)
      } else {
        if (step === 'step3') {
          void formik.submitForm()
        } else {
          setStep((step) => (step === 'step1' ? 'step2' : 'step3'))
        }
      }
    })
  }, [formik, step, setStep])

  return (
    <div className="flex w-full justify-end">
      {step !== 'step1' && (
        <Button
          type="button"
          variant="blue"
          className="mr-2 flex items-center"
          onClick={() => setStep((step) => (step === 'step3' ? 'step2' : 'step1'))}
        >
          <div className="mr-4">
            <Arrow color="white" />
          </div>
          {t('common:back')}
        </Button>
      )}
      <Button type="button" variant="blue" className="flex items-center" onClick={handleClick}>
        {step !== 'step3' ? (
          <>
            {t('common:next')}
            <div className="ml-4 rotate-180">
              <Arrow color="white" />
            </div>
          </>
        ) : (
          t('common:registration')
        )}
      </Button>
    </div>
  )
}
