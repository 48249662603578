export function RecomendationsIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="recomendations_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <g id="Union_1" data-name="Union 1" transform="translate(41 605)" fill="none">
          <path d="M6-574H3a3,3,0,0,1-3-3v-8a3,3,0,0,1,3-3H13a3,3,0,0,1,3,3v8a3,3,0,0,1-3,3H10l-2,2Z" stroke="none" />
          <path
            d="M 8.000031471252441 -573.4146118164062 L 9.58568000793457 -575.000244140625 L 12.99960041046143 -575.000244140625 C 14.10272979736328 -575.000244140625 15.00020027160645 -575.8972778320312 15.00020027160645 -576.9999389648438 L 15.00020027160645 -585 C 15.00020027160645 -586.1026611328125 14.10272979736328 -586.9996948242188 12.99960041046143 -586.9996948242188 L 2.99970006942749 -586.9996948242188 C 1.89706015586853 -586.9996948242188 1.00000011920929 -586.1026611328125 1.00000011920929 -585 L 1.00000011920929 -576.9999389648438 C 1.00000011920929 -575.8972778320312 1.89706015586853 -575.000244140625 2.99970006942749 -575.000244140625 L 6.414100170135498 -575.000244140625 L 8.000031471252441 -573.4146118164062 M 8.000100135803223 -572.0004272460938 L 5.999939918518066 -574.000244140625 L 2.99970006942749 -574.000244140625 C 1.342800140380859 -574.000244140625 1.358032193365943e-07 -575.343017578125 1.358032193365943e-07 -576.9999389648438 L 1.358032193365943e-07 -585 C 1.358032193365943e-07 -586.6569213867188 1.342800140380859 -587.9996948242188 2.99970006942749 -587.9996948242188 L 12.99960041046143 -587.9996948242188 C 14.6564998626709 -587.9996948242188 16.00020027160645 -586.6569213867188 16.00020027160645 -585 L 16.00020027160645 -576.9999389648438 C 16.00020027160645 -575.343017578125 14.6564998626709 -574.000244140625 12.99960041046143 -574.000244140625 L 9.999899864196777 -574.000244140625 L 8.000100135803223 -572.0004272460938 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <g id="Union_2" data-name="Union 2" transform="translate(43.343 606.964)" fill="none">
          <path
            d="M1.768-582.7a2.5,2.5,0,0,1,0-3.535,2.5,2.5,0,0,1,3.536,0l.353.353.353-.353a2.5,2.5,0,0,1,3.536,0,2.5,2.5,0,0,1,0,3.535l-.354.354h0l-3.536,3.535Z"
            stroke="none"
          />
          <path
            d="M 5.65657901763916 -580.22216796875 L 8.838870048522949 -583.4036865234375 C 9.423270225524902 -583.9886474609375 9.423270225524902 -584.9403686523438 8.839189529418945 -585.5250244140625 C 8.555789947509766 -585.8084106445312 8.179009437561035 -585.9644775390625 7.778249740600586 -585.9644775390625 C 7.377490043640137 -585.9644775390625 7.000710010528564 -585.8084106445312 6.717339992523193 -585.5250244140625 L 5.656949996948242 -584.464599609375 L 4.596590042114258 -585.5250244140625 C 4.31318998336792 -585.8084106445312 3.936409950256348 -585.9644775390625 3.53564977645874 -585.9644775390625 C 3.134889841079712 -585.9644775390625 2.75810980796814 -585.8084106445312 2.474709749221802 -585.5250244140625 C 1.889949917793274 -584.9402465820312 1.889949917793274 -583.98876953125 2.474699974060059 -583.4039916992188 L 5.65657901763916 -580.22216796875 M 5.656499862670898 -578.8080444335938 L 1.767599821090698 -582.6968994140625 C 0.7910998463630676 -583.6734008789062 0.7910998463630676 -585.255615234375 1.767599821090698 -586.2321166992188 C 2.744100093841553 -587.2086791992188 4.327199935913086 -587.2086791992188 5.303699970245361 -586.2321166992188 L 5.656949996948242 -585.8788452148438 L 6.010200023651123 -586.2321166992188 C 6.98669958114624 -587.2086791992188 8.569801330566406 -587.2086791992188 9.546299934387207 -586.2321166992188 C 10.52190017700195 -585.255615234375 10.52190017700195 -583.6734008789062 9.546299934387207 -582.6968994140625 L 9.192560195922852 -582.34326171875 L 9.192600250244141 -582.3432006835938 L 5.656499862670898 -578.8080444335938 Z"
            stroke="none"
            fill="gray"
          />
        </g>
      </g>
    </svg>
  )
}
