export function HomeIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="home_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <g id="Path_2" data-name="Path 2" transform="translate(43 18)" fill="none">
          <path d="M0,3.913,6-.5l6,4.413V13a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1Z" stroke="none" />
          <path
            d="M 6 0.7413797378540039 L 1 4.419063568115234 L 1 13 L 11 13 L 11 4.419063568115234 L 6 0.7413797378540039 M 6 -0.5 L 12 3.913220405578613 L 12 13 C 12 13.55228042602539 11.55228042602539 14 11 14 L 1 14 C 0.4477100372314453 14 0 13.55228042602539 0 13 L 0 3.913220405578613 L 6 -0.5 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <path
          id="Path_1"
          data-name="Path 1"
          d="M41,23.023,49,17l8,6.023"
          transform="translate(0 1)"
          fill="none"
          stroke="gray"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g
          id="Rectangle_9"
          data-name="Rectangle 9"
          transform="translate(47 25)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="4" height="7" stroke="none" />
          <rect x="0.5" y="0.5" width="3" height="6" fill="none" />
        </g>
      </g>
    </svg>
  )
}
