import * as d from 'decoders'
import { storageValue, useStorageValue } from 'src/helpers/storage'

export type State = boolean

export const sidebarStorage = storageValue<State>(
  'app:sidebar',
  (value) => JSON.stringify(value),
  (serializedValue) => {
    return d.boolean.verify(JSON.parse(serializedValue))
  }
)

export function useSidebar(): State {
  const theme = useStorageValue(sidebarStorage)

  return theme ?? true
}
