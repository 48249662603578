import { format } from 'date-fns'
import { useFormikContext } from 'formik'
import type * as Api from 'src/api'
import { useTranslatable } from 'src/hooks/locale/utils'
import * as Table from 'src/tailwind/components/Table'

type formikValuesType = Api.postMasterRegistrationParams['body'] &
  Api.postLecturerRegistrationParams['body'] & {
    readonly result: Api.Registration | undefined
  }

export default function ResultPage(): JSX.Element | null {
  const t = useTranslatable()
  const { values } = useFormikContext<formikValuesType>()

  if (values.result == null) {
    return null
  }
  return (
    <div className="resume relative flex w-full flex-col rounded-card bg-card p-[24px] text-primaryTextColor">
      <h1 className="text-center font-bold">{t('registration:statement_in_progress')}</h1>
      <div>
        {t('registration:registration_number')}: <span className="text-primaryRed">{values.result.id}</span>
      </div>
      <div>
        {t('registration:registration_date')}: <span>{format(new Date(), 'dd/MM/yyyy')} </span>
      </div>
      <div className="my-2 flex flex-col items-end">
        <div className="mb-2">
          {t('registration:applicant')}: {values.result.firstname} {values.result.lastname} ({values.step1.firstname_en}{' '}
          {values.step1.lastname_en})
        </div>
        <div className="mb-2">
          {t('person:personal_number')}: {values.step1.pers_num}
        </div>
        <div className="mb-2">
          {t('person:address')}: {values.step1.actual_address}
        </div>
        <div className="mb-2">
          {t('person:phone_number')}: {values.step1.mobile_phone1}
        </div>
        <div className="mb-2">
          {t('person:email')}: <a href={values.result.mail}>{values.result.mail}</a>
        </div>
        <div className="my-2 w-full">
          <h3 className="text-center font-bold">{t('registration:registration_made')}</h3>
          <Table.Table className="table min-w-[280px] text-center">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  <div className="text-center">{t('faculty:faculty')}</div>
                </Table.Th>
                <Table.Th>
                  <div className="text-center">{t('program:program_name')}</div>{' '}
                </Table.Th>
                <Table.Th>
                  <div className="text-center">{t('achievement:direction')}</div>{' '}
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {values.result.regProgs.map((item) => (
                <Table.Tr key={item.id}>
                  <Table.Td className="text-start">
                    <div>{item.program.faculty?.name}</div>
                  </Table.Td>
                  <Table.Td className="text-start">
                    <div>{item.program.name}</div>
                  </Table.Td>
                  <Table.Td className="text-start">
                    <div>{item.program.direction}</div>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.Table>
        </div>
        <div className="text-primaryRed">{t('registration:result_warning')}</div>
      </div>
    </div>
  )
}
