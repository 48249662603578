export function RatingIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="home_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <path
          id="Polygon_3"
          data-name="Polygon 3"
          d="M4.716.846a.3.3,0,0,1,.569,0l.9,2.674a.3.3,0,0,0,.276.2L9.185,3.8a.3.3,0,0,1,.18.533L7.177,6.094a.3.3,0,0,0-.1.315l.779,2.76a.3.3,0,0,1-.465.325l-2.215-1.6a.3.3,0,0,0-.352,0l-2.215,1.6a.3.3,0,0,1-.465-.325l.779-2.76a.3.3,0,0,0-.1-.315L.635,4.331A.3.3,0,0,1,.815,3.8L3.54,3.724a.3.3,0,0,0,.276-.2Z"
          transform="translate(44 19.5)"
          fill="gray"
        />
        <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(41 17)" fill="none" stroke="gray" strokeWidth="1">
          <circle cx="8" cy="8" r="8" stroke="none" />
          <circle cx="8" cy="8" r="7.5" fill="none" />
        </g>
      </g>
    </svg>
  )
}
