import classnames from 'classnames'
import * as React from 'react'
import { NavLink } from 'react-router-dom'

export const SidebarLink = React.forwardRef<HTMLAnchorElement, React.ComponentProps<typeof NavLink>>(
  function SidebarLink({ className, children, ...props }, ref) {
    return (
      <NavLink
        {...props}
        ref={ref}
        className={({ isActive, isPending, isTransitioning }) =>
          classnames(
            'hover-left-animation hover:bg-hover-nav hover:text-bodyText',
            {
              'active-sidebar-item bg-hover-nav': isActive,
            },
            typeof className === 'function' ? className({ isActive, isPending, isTransitioning }) : className
          )
        }
      >
        {children}
      </NavLink>
    )
  }
)
