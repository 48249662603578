export function ThemeLightIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="theme_icon" transform="translate(-299.999 -561)">
        <rect
          id="Rectangle_161"
          data-name="Rectangle 161"
          width="18"
          height="18"
          transform="translate(299.999 561)"
          fill="gray"
          opacity="0"
        />
        <rect
          id="Rectangle_304"
          data-name="Rectangle 304"
          width="16"
          height="16"
          transform="translate(301 562)"
          fill="gray"
          opacity="0"
        />
        <path
          id="Ellipse_1026"
          data-name="Ellipse 1026"
          d="M5,1A4,4,0,1,0,9,5,4,4,0,0,0,5,1M5,0A5,5,0,1,1,0,5,5,5,0,0,1,5,0Z"
          transform="translate(304 565)"
          fill="gray"
        />
        <path
          id="Line_68"
          data-name="Line 68"
          d="M0,1.5A.5.5,0,0,1-.5,1V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V1A.5.5,0,0,1,0,1.5Z"
          transform="translate(309 562.5)"
          fill="gray"
        />
        <path
          id="Line_71"
          data-name="Line 71"
          d="M0,1.5A.5.5,0,0,1-.5,1V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V1A.5.5,0,0,1,0,1.5Z"
          transform="translate(316.5 570) rotate(90)"
          fill="gray"
        />
        <path
          id="Line_73"
          data-name="Line 73"
          d="M0,1.5A.5.5,0,0,1-.5,1V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V1A.5.5,0,0,1,0,1.5Z"
          transform="translate(314.303 575.303) rotate(135)"
          fill="gray"
        />
        <path
          id="Line_75"
          data-name="Line 75"
          d="M0,1.5A.5.5,0,0,1-.5,1V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V1A.5.5,0,0,1,0,1.5Z"
          transform="translate(303.697 575.303) rotate(-135)"
          fill="gray"
        />
        <path
          id="Line_69"
          data-name="Line 69"
          d="M0,1.5A.5.5,0,0,1-.5,1V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V1A.5.5,0,0,1,0,1.5Z"
          transform="translate(309 576.5)"
          fill="gray"
        />
        <path
          id="Line_70"
          data-name="Line 70"
          d="M0,1.5A.5.5,0,0,1-.5,1V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V1A.5.5,0,0,1,0,1.5Z"
          transform="translate(302.5 570) rotate(90)"
          fill="gray"
        />
        <path
          id="Line_72"
          data-name="Line 72"
          d="M0,1.5A.5.5,0,0,1-.5,1V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V1A.5.5,0,0,1,0,1.5Z"
          transform="translate(304.404 565.404) rotate(135)"
          fill="gray"
        />
        <path
          id="Line_74"
          data-name="Line 74"
          d="M0,1.5A.5.5,0,0,1-.5,1V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V1A.5.5,0,0,1,0,1.5Z"
          transform="translate(313.596 565.404) rotate(-135)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
