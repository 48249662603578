export function ResumeIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="resume_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <g
          id="Rectangle_31"
          data-name="Rectangle 31"
          transform="translate(43 17)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="12" height="16" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="11" height="15" rx="1.5" fill="none" />
        </g>
        <rect
          id="Rectangle_28"
          data-name="Rectangle 28"
          width="6"
          height="1"
          rx="0.5"
          transform="translate(46 27)"
          fill="gray"
        />
        <rect
          id="Rectangle_29"
          data-name="Rectangle 29"
          width="6"
          height="1"
          rx="0.5"
          transform="translate(46 29)"
          fill="gray"
        />
        <path
          id="Ellipse_5"
          data-name="Ellipse 5"
          d="M2,1.333A.667.667,0,1,0,2.667,2,.667.667,0,0,0,2,1.333M2,0A2,2,0,1,1,0,2,2,2,0,0,1,2,0Z"
          transform="translate(47 19)"
          fill="gray"
        />
        <path
          id="Rectangle_32"
          data-name="Rectangle 32"
          d="M2,0H4A2,2,0,0,1,6,2V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V2A2,2,0,0,1,2,0Z"
          transform="translate(46 23)"
          fill="none"
        />
        <path
          id="Subtraction_5"
          data-name="Subtraction 5"
          d="M1-501H0v-1a2,2,0,0,1,2-2H4a2,2,0,0,1,2,2v1H5v-1a1,1,0,0,0-1-1H2a1,1,0,0,0-1,1v1Z"
          transform="translate(46 527)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
