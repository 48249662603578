import classnames from 'classnames'
import React from 'react'

export const SurveyTable = React.forwardRef<HTMLTableElement, JSX.IntrinsicElements['table']>(function Table(
  { children, className, ...props },
  ref
) {
  return (
    <div className="w-full overflow-x-scroll">
      <table
        className={classnames(
          'mt-3 min-w-full border-separate border-spacing-0 overflow-hidden rounded-[12px] border border-borderColor bg-transparent text-title',
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </table>
    </div>
  )
})

export const Thead = React.forwardRef<HTMLTableSectionElement, JSX.IntrinsicElements['thead']>(function Thead(
  { children, ...props },
  ref
) {
  return (
    <thead {...props} ref={ref}>
      {children}
    </thead>
  )
})
export const Tbody = React.forwardRef<HTMLTableSectionElement, JSX.IntrinsicElements['tbody']>(function Tbody(
  { children, ...props },
  ref
) {
  return (
    <tbody {...props} ref={ref}>
      {children}
    </tbody>
  )
})
export const Tfoot = React.forwardRef<HTMLTableSectionElement, JSX.IntrinsicElements['tfoot']>(function Tfoot(
  { children, ...props },
  ref
) {
  return (
    <tfoot {...props} ref={ref}>
      {children}
    </tfoot>
  )
})
export const Tr = React.forwardRef<HTMLTableRowElement, JSX.IntrinsicElements['tr']>(function Tr(
  { children, className, ...props },
  ref
) {
  return (
    <tr className={classnames('hover:bg-transparent', className)} {...props} ref={ref}>
      {children}
    </tr>
  )
})

export const Th = React.forwardRef<HTMLTableCellElement, JSX.IntrinsicElements['th']>(function Th(
  { children, className, ...props },
  ref
) {
  return (
    <th
      className={classnames(
        'border-r border-borderGrey bg-card px-3 py-[12px] text-center font-normal last:border-r-0',
        className
      )}
      {...props}
      ref={ref}
    >
      {children}
    </th>
  )
})

export const Td = React.forwardRef<HTMLTableCellElement, JSX.IntrinsicElements['td']>(function Td(
  { children, className, ...props },
  ref
) {
  return (
    <td className={classnames('px-2 py-[10px]', className)} {...props} ref={ref}>
      {children}
    </td>
  )
})
