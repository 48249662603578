import { useFormikContext } from 'formik'
import { useRef, useState } from 'react'
import { useClickAway, useDebounce } from 'react-use'
import * as Api from 'src/api'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import PopoverComponent from 'src/views/components/PopoverComponent'

export default function Address({ type }: { type: 'legal_address' | 'actual_address' }): JSX.Element {
  const t = useTranslatable()
  const formik = useFormikContext<Api.postMasterRegistrationParams['body']>()
  const locale = useLocale()
  const [autocomplete, setAutocomplete] = useState<Api.getRegistrationAddressesOk | null>([])
  const translateKey = type === 'legal_address' ? 'juridical_address' : 'actual_address'
  const ref = useRef(null)

  useDebounce(
    async () => {
      try {
        if (autocomplete != null && formik.values.step1[type].length > 2) {
          const addresses = await Api.getRegistrationAddresses({
            headers: { 'Accept-Language': locale },
            query: { query: formik.values.step1[type] },
          })
          setAutocomplete(addresses)
        } else {
          setAutocomplete([])
        }
      } catch (error) {
        console.error(error)
      }
    },
    500,
    [formik.values.step1[type]]
  )
  useClickAway(ref, () => {
    setAutocomplete([])
  })

  return (
    <div className="relative flex flex-col">
      <TextareaInput
        name={`step1.${type}`}
        label={t(`person:${translateKey}`)}
        rows={3}
        instructions={
          <PopoverComponent placement="top">
            <span className="text-primaryTextColor">{t(`registration:${translateKey}`)}</span>
          </PopoverComponent>
        }
        required
      />
      <div ref={ref} className="absolute top-[115px] z-10">
        {autocomplete != null && autocomplete.length > 0 && (
          <ul className="max-h-[200px] w-full overflow-y-scroll bg-primaryWhite">
            {autocomplete.map((address) => (
              <li
                key={address.result}
                className="p-2 hover:bg-card"
                onClick={() => {
                  void formik.setFieldValue(`step1.${type}`, address.result)
                  setAutocomplete(null)
                }}
              >
                {address.result}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
