import { defered } from 'src/helpers/defered'

const deferedGoogle = defered<typeof google>()

let prepared = false

export async function prepare(): Promise<typeof google> {
  try {
    if (!prepared) {
      prepared = true

      await new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = 'https://accounts.google.com/gsi/client'
        script.async = true
        script.defer = true
        script.onload = resolve
        script.onerror = reject

        document.body.appendChild(script)
      })

      deferedGoogle.resolve(google)
    }
  } catch (err) {
    deferedGoogle.reject(err)
  }

  return await deferedGoogle.promise
}
