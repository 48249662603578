export function ThemeIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="theme_icon" transform="translate(-299.999 -561)">
        <rect
          id="Rectangle_161"
          data-name="Rectangle 161"
          width="18"
          height="18"
          transform="translate(299.999 561)"
          fill="gray"
          opacity="0"
        />
        <rect
          id="Rectangle_304"
          data-name="Rectangle 304"
          width="16"
          height="16"
          transform="translate(301 562)"
          fill="gray"
          opacity="0"
        />
        <g id="Subtraction_228" data-name="Subtraction 228" transform="translate(1078 321)" fill="none">
          <path
            d="M-769,257a8.009,8.009,0,0,1-8-8,8.009,8.009,0,0,1,8-8,8.018,8.018,0,0,1,2.147.291A5.983,5.983,0,0,0-771,247a6.007,6.007,0,0,0,6,6,5.962,5.962,0,0,0,3.456-1.095,7.989,7.989,0,0,1-2.891,3.666A7.95,7.95,0,0,1-769,257Z"
            stroke="none"
          />
          <path
            d="M -768.9996337890625 256.0003967285156 C -767.5621337890625 256.0003967285156 -766.1813354492188 255.5681610107422 -765.006591796875 254.7503814697266 C -764.6554565429688 254.5059509277344 -764.329833984375 254.2325592041016 -764.0325927734375 253.9337768554688 C -764.3508911132812 253.9776611328125 -764.6740112304688 253.9998931884766 -765 253.9998931884766 C -768.8599853515625 253.9998931884766 -772.0003051757812 250.8595733642578 -772.0003051757812 246.9996032714844 C -772.0003051757812 245.1021728515625 -771.2448120117188 243.3477172851562 -769.9749145507812 242.0678863525391 C -773.3746337890625 242.5440063476562 -775.9996948242188 245.471435546875 -775.9996948242188 249.0003051757812 C -775.9996948242188 252.8601684570312 -772.8594970703125 256.0003967285156 -768.9996337890625 256.0003967285156 M -768.9996337890625 257.0003967285156 C -773.410888671875 257.0003967285156 -776.9996948242188 253.4115753173828 -776.9996948242188 249.0003051757812 C -776.9996948242188 244.5890350341797 -773.410888671875 241.0001831054688 -768.9996337890625 241.0001831054688 C -768.2706298828125 241.0001831054688 -767.5482177734375 241.0982360839844 -766.8524780273438 241.2916107177734 C -769.3333740234375 242.0960540771484 -771.0003051757812 244.3891448974609 -771.0003051757812 246.9996032714844 C -771.0003051757812 250.3081817626953 -768.30859375 252.9998931884766 -765 252.9998931884766 C -763.7537841796875 252.9998931884766 -762.5587768554688 252.6213989257812 -761.5441284179688 251.9053039550781 C -762.1232299804688 253.3907623291016 -763.1234741210938 254.6579742431641 -764.435302734375 255.5711212158203 C -765.7785034179688 256.5061645507812 -767.3568725585938 257.0003967285156 -768.9996337890625 257.0003967285156 Z"
            stroke="none"
            fill="gray"
          />
        </g>
      </g>
    </svg>
  )
}
