interface Props {
  fill?: string
  opacity?: string
}

export default function PolygonIcon({ fill, opacity }: Props): JSX.Element {
  return (
    <svg className="scale-[1.2]" xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10">
      <path
        id="Polygon_9"
        data-name="Polygon 9"
        d="M5,0l5,7H0Z"
        transform="translate(7) rotate(90)"
        fill={fill ?? '#fff'}
        opacity={opacity ?? '0.3'}
        data-testid="polygonIcon"
      />
    </svg>
  )
}
