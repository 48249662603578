import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { type UserAnswerOption } from 'src/api'
import * as SurveyTable from 'src/tailwind/components/SurveyTable'
import * as Yup from 'yup'

interface Option {
  readonly id: number
  readonly title: string // გამოდის ჩეკბოქსის ლეიბლად
  readonly customAllowed: boolean
  readonly fcolor: string
  readonly bcolor: string
}

interface Criterias {
  readonly id: number
  readonly title: string // გამოდის თავზე ჰორიზონტალურ ხაზში
  readonly fcolor: string
  readonly bcolor: string
}

interface MultiCheckboxesType {
  readonly id: number
  readonly type: number
  readonly title: string
  readonly required: boolean
  readonly fcolor: string
  readonly bcolor: string
  readonly options: readonly Option[]
  readonly criterias: readonly Criterias[]
}

interface checkCriterias {
  readonly criteriaId: number
}
interface QuestionMultiCheckboxesValue {
  readonly optionId: number
  readonly criterias: readonly checkCriterias[]
}

interface TypeMultiCheckboxesProps {
  readonly question: MultiCheckboxesType
  readonly name: string
  readonly index: number
}

export function SurveyMultiCheckboxSchema(message: string, isRequired: boolean, optionsLength: number): Yup.Schema {
  if (!isRequired) return Yup.mixed()
  return Yup.array(Yup.object()).min(optionsLength, message).required(message)
}

export default function SurveyMultiCheckboxInput({
  question,
  name,
  index,
}: TypeMultiCheckboxesProps): React.ReactElement {
  const { errors, isValid, submitCount } = useFormikContext<{ [k: string]: readonly UserAnswerOption[] | undefined }>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<undefined | readonly QuestionMultiCheckboxesValue[]>({ name })

  const values = React.useRef(
    React.useMemo(() => {
      return Object.fromEntries(
        question.options.flatMap((o) => {
          return question.criterias.map((c) => {
            return [
              `checkbox-${o.id}-${c.id}`,
              field.value?.find((v) => v.optionId === o.id)?.criterias.find((v) => v.criteriaId === c.id) != null,
            ]
          })
        })
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  function updateValue(): void {
    void helpers.setValue(
      question.options.flatMap((option) => {
        const criterias = question.criterias.flatMap((c) => {
          const value = values.current[`checkbox-${option.id}-${c.id}`]!

          if (value) {
            return {
              criteriaId: c.id,
            }
          }

          return []
        })

        if (criterias.length > 0) {
          return {
            optionId: option.id,
            criterias,
          }
        }

        return []
      })
    )
  }

  return (
    <div className="mb-3">
      <div className="flex">
        <div className="mr-2">{index}.</div>
        <div className="font-bold">
          {question.title}
          {question.required && <span className="ml-1 text-primaryRed">*</span>}
        </div>
      </div>
      <SurveyTable.SurveyTable>
        <SurveyTable.Tbody>
          <SurveyTable.Tr>
            <SurveyTable.Th />
            {question.criterias.map((criteria) => (
              <SurveyTable.Th key={criteria.id}>{criteria.title}</SurveyTable.Th>
            ))}
          </SurveyTable.Tr>
          {question.options.map((option) => (
            <SurveyTable.Tr key={option.id}>
              <SurveyTable.Td>{option.title}</SurveyTable.Td>
              {question.criterias.map((c) => (
                <SurveyTable.Td className="text-center" key={c.id}>
                  <label>
                    <input
                      type="checkbox"
                      checked={values.current[`checkbox-${option.id}-${c.id}`]}
                      onChange={(e) => {
                        values.current[`checkbox-${option.id}-${c.id}`] = e.target.checked

                        updateValue()
                      }}
                    />
                  </label>
                </SurveyTable.Td>
              ))}
            </SurveyTable.Tr>
          ))}
        </SurveyTable.Tbody>
      </SurveyTable.SurveyTable>
      <div className="h-[30px] px-[12px] py-0 text-sm text-primaryRed">
        {!isValid && submitCount > 0 ? errors[`field-${question.id}`] : null}
      </div>
    </div>
  )
}
