import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useTranslatable } from 'src/hooks/locale/utils'

import { useLocale } from 'src/hooks/locale/locale'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function RegistrationMediaFilePage(): React.ReactElement | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('file:file_download')} />
      <React.Suspense fallback={<Loader className="mx-auto my-10 flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): React.ReactElement | null {
  const { id } = useParams()
  const locale = useLocale()

  const mediaFile = useApi({
    endpoint: Api.getRegistrationFilesDownload,
    params: React.useMemo(
      () => ({
        headers: {
          'Accept-Language': locale,
        },
        args: {
          id: id!,
        },
      }),
      [locale, id]
    ),
  })

  location.replace(mediaFile.data.url)

  return null
}
