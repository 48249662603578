import * as ProSolidSvgIcons from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslatable } from 'src/hooks/locale/utils'

export default function MaintenanceModePage(): React.ReactElement | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('system:technical_works_are_underway')} />

      <div className="rounded border px-3 py-5 text-center">
        <h1 className="mb-4">
          <FontAwesomeIcon icon={ProSolidSvgIcons.faConstruction} className="text-lightSecondaryWarning" />{' '}
          {t('system:technical_works_are_underway')}
        </h1>
      </div>
    </>
  )
}
