import * as FreeBrandsSvgIcons from '@fortawesome/free-brands-svg-icons'
import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet-async'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'

export default function MobileApplication(): JSX.Element {
  const t = useTranslatable()
  return (
    <>
      <Helmet title={t('uncategorized:get_app')} />
      <Section
        title={t('uncategorized:get_app')}
        icon={<FontAwesomeIcon icon={ProRegularSvgIcons.faMobile} size="2xl" />}
      >
        <div className="flex w-full flex-wrap items-center">
          <a
            href="https://apps.apple.com/us/app/argus-lms/id1559920018"
            target="_blank"
            rel="noreferrer"
            className="mr-4"
          >
            <Button variant="red" className="w-[130px]">
              <FontAwesomeIcon icon={FreeBrandsSvgIcons.faAppStore} size="2xl" color="white" />
              <span className="ml-2 font-semibold">IOS</span>
            </Button>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=ge.edu.iliauni.apps.argus"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="red" className="w-[130px]">
              <FontAwesomeIcon icon={FreeBrandsSvgIcons.faAndroid} size="2xl" color="white" />
              <span className="ml-2 font-semibold">Android</span>
            </Button>
          </a>
        </div>
      </Section>
    </>
  )
}
