export function StatementsIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="statements_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M2,1A1,1,0,0,0,1,2V14a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V4.414L7.586,1H2M2,0H8l4,4V14a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
          transform="translate(43 17)"
          fill="gray"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M1,1.414V4H3.586L1,1.414M0,0H1L5,4V5H1A1,1,0,0,1,0,4Z"
          transform="translate(50 17)"
          fill="gray"
        />
        <rect
          id="Rectangle_26"
          data-name="Rectangle 26"
          width="3"
          height="1"
          rx="0.5"
          transform="translate(46 20)"
          fill="gray"
        />
        <rect
          id="Rectangle_27"
          data-name="Rectangle 27"
          width="6"
          height="1"
          rx="0.5"
          transform="translate(46 23)"
          fill="gray"
        />
        <rect
          id="Rectangle_28"
          data-name="Rectangle 28"
          width="6"
          height="1"
          rx="0.5"
          transform="translate(46 26)"
          fill="gray"
        />
        <rect
          id="Rectangle_29"
          data-name="Rectangle 29"
          width="6"
          height="1"
          rx="0.5"
          transform="translate(46 29)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
