import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported, type Messaging } from 'firebase/messaging'
import env from './env'

initializeApp({
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  projectId: env.FIREBASE_PROJECT_ID,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
  appId: env.FIREBASE_APP_ID,
  measurementId: env.FIREBASE_MEASUREMENT_ID,
})

let messaging: Messaging | null = null

void registerServiceWorker()

async function registerServiceWorker(): Promise<void> {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register('/sw.js', {
        type: 'classic',
      })
    } catch (err) {
      console.error('Error during service worker registration:', err)
    }
  }
}

export async function getMessagingToken(): Promise<string> {
  if (await isSupported()) {
    if (messaging == null) messaging = getMessaging()

    return await getToken(messaging, {
      vapidKey: env.VAPID_KEY,
      serviceWorkerRegistration: await navigator.serviceWorker.getRegistration(),
    })
  }

  throw new Error('Not Supported!')
}

export default {}
