import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { type UserAnswerOption } from 'src/api'
import * as SurveyTable from 'src/tailwind/components/SurveyTable'
import * as Yup from 'yup'

interface Option {
  readonly id: number
  readonly title: string // გამოდის ჩეკბოქსის ლეიბლად
  readonly customAllowed: boolean
  readonly fcolor: string
  readonly bcolor: string
}

interface Criterias {
  readonly id: number
  readonly title: string // გამოდის თავზე ჰორიზონტალურ ხაზში
  readonly fcolor: string
  readonly bcolor: string
}

interface MultiRadiosType {
  readonly id: number
  readonly type: number
  readonly title: string
  readonly required: boolean
  readonly fcolor: string
  readonly bcolor: string
  readonly options: readonly Option[]
  readonly criterias: readonly Criterias[]
}

interface checkCriterias {
  readonly criteriaId: number
}
interface QuestionMultiRadiosValue {
  readonly optionId: number
  readonly criterias: readonly checkCriterias[]
}

interface TypeMultiRadiosProps {
  readonly question: MultiRadiosType
  readonly name: string
  readonly index: number
}

export function SurveyMultiRadioSchema(message: string, isRequired: boolean, optionsLength: number): Yup.Schema {
  if (!isRequired) return Yup.mixed()
  return Yup.array(Yup.object()).min(optionsLength, message).required(message)
}

export default function SurveyMultiRadioInput({ question, name, index }: TypeMultiRadiosProps): React.ReactElement {
  const { errors, isValid, submitCount } = useFormikContext<{ [k: string]: readonly UserAnswerOption[] | undefined }>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<undefined | readonly QuestionMultiRadiosValue[]>({ name })

  const labelPrefix = `label-${React.useId()}`

  const values = React.useRef(
    React.useMemo(() => {
      return Object.fromEntries(
        question.options.map((o) => {
          return [`radio-${o.id}`, field.value?.find((v) => v.optionId === o.id)?.criterias[0]?.criteriaId ?? null]
        })
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  function updateValue(): void {
    void helpers.setValue(
      question.options.flatMap((option) => {
        const criteriaId = values.current[`radio-${option.id}`]

        if (criteriaId != null) {
          return {
            optionId: option.id,
            criterias: [
              {
                criteriaId,
              },
            ],
          }
        }

        return []
      })
    )
  }

  return (
    <div className="mb-2">
      <div className="flex">
        <div className="mr-1">{index}.</div>
        <div className="font-bold">
          {question.title}
          {question.required && <span className="ml-1 text-primaryRed">*</span>}
        </div>
      </div>
      <SurveyTable.SurveyTable className="mb-2">
        <SurveyTable.Tbody>
          <SurveyTable.Tr>
            <SurveyTable.Th className="first:min-w-[30%]" />
            {question.criterias.map((criteria) => (
              <SurveyTable.Th key={criteria.id}>{criteria.title}</SurveyTable.Th>
            ))}
          </SurveyTable.Tr>
          {question.options.map((option) => (
            <SurveyTable.Tr key={option.id}>
              <SurveyTable.Td className="border-r border-borderGrey">{option.title}</SurveyTable.Td>
              {question.criterias.map((c) => (
                <SurveyTable.Td className="border-r border-borderGrey text-center last:border-r-0" key={c.id}>
                  <label>
                    <input
                      className="size-4 bg-gray-100"
                      type="radio"
                      id={`${labelPrefix}-${option.id}`}
                      name={`${labelPrefix}-${option.id}`}
                      // value={`${labelPrefix}-${option.id}`}
                      checked={values.current[`radio-${option.id}`] === c.id}
                      onChange={() => {
                        values.current[`radio-${option.id}`] = c.id

                        updateValue()
                      }}
                    />
                  </label>
                </SurveyTable.Td>
              ))}
            </SurveyTable.Tr>
          ))}
        </SurveyTable.Tbody>
      </SurveyTable.SurveyTable>
      <div className="mb-3 text-title text-primaryRed">
        {!isValid && submitCount > 0 ? errors[`field-${question.id}`] : null}
      </div>
    </div>
  )
}
