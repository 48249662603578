import { LoudspeakerWhiteIcon } from 'src/assets/icons/customIcons/LoudspeakerWhite'
import { useAuth } from 'src/hooks/auth/auth'

export function Alert(): JSX.Element | null {
  const auth = useAuth()

  if (auth.state === 'Authenticated' && auth.profile.profileStatus?.active === false) {
    return (
      <div className="mb-[20px] flex items-center justify-center rounded-card bg-darkblueMessages px-4 py-3 print:hidden">
        <div className="flex min-h-[48px] min-w-[48px] items-center justify-center rounded-full bg-[#4e536b]">
          <LoudspeakerWhiteIcon />
        </div>
        <span className="mx-6 w-full text-white">{auth.profile.profileStatus.message}</span>
        {/* <div className=' rounded-full min-h-[48px] min-w-[48px] flex justify-center items-center hover:bg-[#4e536b] cursor-pointer'>
        <CloseIcon />
      </div> */}
      </div>
    )
  }

  return null
}
