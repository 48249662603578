export function SelectedCoursesIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="selectedcourses_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <g
          id="Rectangle_11"
          data-name="Rectangle 11"
          transform="translate(42 18)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="14" height="14" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" fill="none" />
        </g>
        <path
          id="Path_3"
          data-name="Path 3"
          d="M45.251,24.947l3.117,2.723,4.425-5.392"
          fill="none"
          stroke="gray"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
