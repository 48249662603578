import classNames from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { EngIcon } from 'src/assets/icons/customIcons/Eng'
import { GeoIcon } from 'src/assets/icons/customIcons/Geo'
import { useLocale } from 'src/hooks/locale/locale'
import { useLocaleLinks, useTranslatable } from 'src/hooks/locale/utils'

interface Props {
  readonly mobile: boolean
}

export default function LocaleSwitcher({ mobile }: Props): React.ReactElement | null {
  const locale = useLocale()
  const localeLinks = useLocaleLinks()
  const t = useTranslatable()

  return (
    <div className="mt-3" title={t('common:change_language')}>
      {mobile ? (
        <div className="link-text float-left ml-[45px] flex">
          <Link
            to={localeLinks.ka}
            className={classNames(
              'flex cursor-pointer rounded-card px-2 py-1 text-primaryTextColor hover:bg-hover-nav hover:text-primaryTextColor',
              {
                'bg-hover-nav': locale === 'ka',
                'text-disabled': locale === 'en',
              }
            )}
          >
            <GeoIcon />
            <span className="ml-1">{t('common:sidebar_georgian')}</span>
          </Link>
          <Link
            to={localeLinks.en}
            className={classNames(
              'ml-2 flex cursor-pointer rounded-card p-1 px-2 text-primaryTextColor hover:bg-hover-nav hover:text-primaryTextColor',
              {
                'bg-hover-nav': locale === 'en',
                'text-disabled': locale === 'ka',
              }
            )}
          >
            <EngIcon />
            <span className="ml-1">{t('common:sidebar_english')}</span>
          </Link>
        </div>
      ) : (
        <div title={t('common:change_language')} className="ml-[18px]">
          {locale === 'ka' ? (
            <Link to={localeLinks.en}>
              <EngIcon />
            </Link>
          ) : null}
          {locale === 'en' ? (
            <Link to={localeLinks.ka}>
              <GeoIcon />
            </Link>
          ) : null}
        </div>
      )}
    </div>
  )
}
