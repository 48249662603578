export function BetaSign(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.32" height="8.148" viewBox="0 0 22.32 8.148">
      <path
        id="beta_sign"
        d="M-23.22,0h3.648a2.086,2.086,0,0,0,2.316-2.16,2,2,0,0,0-1.56-1.968,1.819,1.819,0,0,0,1.4-1.836A2.019,2.019,0,0,0-19.656-8H-23.22Zm1-4.536v-2.58h2.4a1.258,1.258,0,0,1,1.38,1.3,1.243,1.243,0,0,1-1.38,1.284Zm0,3.648v-2.76h2.448a1.359,1.359,0,0,1,1.488,1.38,1.324,1.324,0,0,1-1.488,1.38Zm6.18-2.016A2.874,2.874,0,0,0-13.092.144a3.149,3.149,0,0,0,2.3-.888l-.432-.588A2.533,2.533,0,0,1-13.008-.6a2.042,2.042,0,0,1-2.088-2H-10.4v-.228A2.815,2.815,0,0,0-13.188-5.94,2.9,2.9,0,0,0-16.044-2.9ZM-13.2-5.2a1.879,1.879,0,0,1,1.908,1.932h-3.8A1.934,1.934,0,0,1-13.2-5.2ZM-7.488.144a1.471,1.471,0,0,0,1.08-.372L-6.672-.9a.868.868,0,0,1-.612.24c-.4,0-.588-.312-.588-.744V-5H-6.7V-5.8H-7.872V-7.38h-.9V-5.8h-.96V-5h.96v3.792A1.186,1.186,0,0,0-7.488.144ZM-1.8,0h.9V-3.984c0-1.4-1.02-1.956-2.244-1.956a3.034,3.034,0,0,0-2.316.96l.42.624a2.3,2.3,0,0,1,1.776-.8c.84,0,1.464.444,1.464,1.224v1.044a2.423,2.423,0,0,0-1.92-.792A1.9,1.9,0,0,0-5.748-1.776,1.93,1.93,0,0,0-3.72.144,2.507,2.507,0,0,0-1.8-.66Zm0-1.224A1.941,1.941,0,0,1-3.4-.5a1.3,1.3,0,0,1-1.428-1.26A1.3,1.3,0,0,1-3.4-3.036a1.941,1.941,0,0,1,1.6.72Z"
        transform="translate(23.22 8.004)"
        fill="gray"
      />
    </svg>
  )
}
