export function EngIcon(): JSX.Element {
  return (
    <svg
      id="eng_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="clip-path">
          <circle
            id="Ellipse_383"
            data-name="Ellipse 383"
            cx="9"
            cy="9"
            r="9"
            transform="translate(40 735)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_4"
        data-name="Mask Group 4"
        transform="translate(-40 -735)"
        opacity="0.5"
        clipPath="url(#clip-path)"
      >
        <circle
          id="Ellipse_386"
          data-name="Ellipse 386"
          cx="9"
          cy="9"
          r="9"
          transform="translate(40 735)"
          fill="#fff"
        />
        <g id="Union_27" data-name="Union 27" transform="translate(40 735)" fill="#de2c2c">
          <path
            d="M 9.499899864196777 18.49990081787109 L 8.500100135803223 18.49990081787109 L 8.500100135803223 9.999899864196777 L 8.500100135803223 9.499899864196777 L 8.000100135803223 9.499899864196777 L -0.4999000132083893 9.499899864196777 L -0.4999000132083893 8.500100135803223 L 8.000100135803223 8.500100135803223 L 8.500100135803223 8.500100135803223 L 8.500100135803223 8.000100135803223 L 8.500100135803223 -0.4999000132083893 L 9.499899864196777 -0.4999000132083893 L 9.499899864196777 8.000100135803223 L 9.499899864196777 8.500100135803223 L 9.999899864196777 8.500100135803223 L 18.49990081787109 8.500100135803223 L 18.49990081787109 9.499899864196777 L 9.999899864196777 9.499899864196777 L 9.499899864196777 9.499899864196777 L 9.499899864196777 9.999899864196777 L 9.499899864196777 18.49990081787109 Z"
            stroke="none"
          />
          <path
            d="M 9.999899864196777 18.99990081787109 L 8.000100135803223 18.99990081787109 L 8.000100135803223 9.999899864196777 L -0.9998999834060669 9.999899864196777 L -0.9998999834060669 8.000100135803223 L 8.000100135803223 8.000100135803223 L 8.000100135803223 -0.9998999834060669 L 9.999899864196777 -0.9998999834060669 L 9.999899864196777 8.000100135803223 L 18.99990081787109 8.000100135803223 L 18.99990081787109 9.999899864196777 L 9.999899864196777 9.999899864196777 L 9.999899864196777 18.99990081787109 Z"
            stroke="none"
            fill="#de2c2c"
          />
        </g>
        <path id="Path_14" data-name="Path 14" d="M7,1V7H1Z" transform="translate(40 747)" fill="#1947b2" />
        <path id="Path_25" data-name="Path 25" d="M1,1V7H7Z" transform="translate(50 747)" fill="#1947b2" />
        <path id="Path_19" data-name="Path 19" d="M7,7V1H1Z" transform="translate(40 733)" fill="#1947b2" />
        <path id="Path_22" data-name="Path 22" d="M1,7V1H7Z" transform="translate(50 733)" fill="#1947b2" />
        <path id="Path_15" data-name="Path 15" d="M1,6V1H6Z" transform="translate(38 745)" fill="#1947b2" />
        <path id="Path_23" data-name="Path 23" d="M6,6V1H1Z" transform="translate(53 745)" fill="#1947b2" />
        <path id="Path_17" data-name="Path 17" d="M1,1V6H6Z" transform="translate(38 736)" fill="#1947b2" />
        <path id="Path_20" data-name="Path 20" d="M6,1V6H1Z" transform="translate(53 736)" fill="#1947b2" />
        <path
          id="Path_16"
          data-name="Path 16"
          d="M46.837,746H44.923l-6.086,6.308v1.671h.288Z"
          transform="translate(0.163)"
          fill="#de2c2c"
        />
        <path
          id="Path_24"
          data-name="Path 24"
          d="M38.837,746H40.75l6.086,6.308v1.671h-.288Z"
          transform="translate(12.163)"
          fill="#de2c2c"
        />
        <path
          id="Path_18"
          data-name="Path 18"
          d="M46.837,753.979H44.923l-6.086-6.308V746h.288Z"
          transform="translate(0.163 -11.979)"
          fill="#de2c2c"
        />
        <path
          id="Path_21"
          data-name="Path 21"
          d="M38.837,753.979H40.75l6.086-6.308V746h-.288Z"
          transform="translate(12.163 -11.979)"
          fill="#de2c2c"
        />
      </g>
      <g id="Ellipse_384" data-name="Ellipse 384" fill="none" stroke="#fff" strokeWidth="1">
        <circle cx="9" cy="9" r="9" stroke="none" />
        <circle cx="9" cy="9" r="8.5" fill="none" />
      </g>
    </svg>
  )
}
