/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import * as Api from 'src/api'
import { UploadConfigMap } from 'src/api'
import File from 'src/assets/icons/customIcons/File'
import { ProgramsIcon } from 'src/assets/icons/customIcons/page-icons/Programs'
import { Plus } from 'src/assets/icons/customIcons/Plus'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { useApi } from 'src/helpers/hooks'
import { type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import FileInput from 'src/views/components/forms/formik/FileInput'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextInput from 'src/views/components/forms/formik/TextInput'
import PopoverComponent from 'src/views/components/PopoverComponent'
import Swal from 'sweetalert2'

export default function Step2(): React.ReactElement | null {
  const formik = useFormikContext<Api.postMasterRegistrationParams['body']>()
  const locale = useLocale()
  const t = useTranslatable()

  const { data: testTypes, isValidating: testTypesValidating } = useApi({
    endpoint: Api.getRegistrationTestTypes,
    params: React.useMemo(
      () => ({
        headers: {
          'Accept-Language': locale,
        },
      }),
      [locale]
    ),
    suspense: false,
  })

  const { data: faculties } = useApi({
    endpoint: Api.getRegistrationFaculties,
    params: React.useMemo(
      () => ({
        headers: {
          'Accept-Language': locale,
        },
      }),
      [locale]
    ),
    shouldFetch: formik.values.step2.testtype.length > 0,
  })

  return (
    <div className="mb-3 flex w-full flex-col">
      <div className="mb-3 mr-0 w-full min-w-[100px] md:!mb-0 md:mr-2 md:w-[49%]">
        <SelectInput
          name="step2.testtype"
          options={testTypes != null ? testTypes.map((s) => ({ label: s.name, value: s.id })) : []}
          label={t('registration:passed_exam_type')}
          disabled={testTypesValidating}
          isLoading={testTypesValidating}
          instructions={
            <PopoverComponent placement="right">
              <span className="text-primaryTextColor">{t('registration:passed_exam_type_instructions')}</span>
            </PopoverComponent>
          }
          required
        />
      </div>

      {formik.values.step2.testtype.length > 0 && (
        <FieldArray name="step2.items">
          {({ remove, push, form }: any) => (
            <>
              {form.values.step2.items.map((item: any, index: number) => (
                <ProgramEntity key={index} index={index} faculties={faculties} remove={remove} />
              ))}
              <Button
                variant="blue"
                type="button"
                className="my-4 flex w-full items-center justify-center"
                onClick={() => push(newItem)}
              >
                <Plus color="white" /> <span className="ml-2 mt-1">{t('common:add_more')}</span>
              </Button>
            </>
          )}
        </FieldArray>
      )}
    </div>
  )
}

const newItem = {
  faculty: '',
  program: '',
  captions: null,
  files_uid: null,
}

interface ProgramEntityProps {
  index: number
  faculties: Api.getRegistrationFacultiesOk | null
  remove: (index: number) => void
}
function ProgramEntity({ index, faculties, remove }: ProgramEntityProps): JSX.Element {
  const t = useTranslatable()
  const formik = useFormikContext<Api.postMasterRegistrationParams['body']>()
  const locale = useLocale()
  const items = formik.values.step2.items[index]

  async function handleDelete(index: number, remove: (index: number) => void): Promise<void> {
    const alert = await Swal.fire({
      title: t('common:do_you_really_want_to_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D6EFD',
      cancelButtonColor: '#6C757D',
      confirmButtonText: t('common:confirm'),
      cancelButtonText: t('common:cancel'),
    })

    if (alert.isConfirmed) {
      remove(index)
    }
  }

  const { data: programs, isValidating: programsValidating } = useApi({
    endpoint: Api.getRegistrationPrograms,
    params: React.useMemo(
      () => ({
        headers: {
          'Accept-Language': locale,
        },
        args: {
          faculty_id: items!.faculty,
          testype_id: formik.values.step2.testtype,
        },
      }),
      [formik.values.step2.testtype, formik.values.step2.items, index, locale]
    ),
    suspense: false,
    shouldFetch: formik.values.step2.testtype.length > 0 && items != null && items.faculty.length > 0,
  })
  const selectedProgramObj = React.useMemo(() => {
    const id = items!.program
    return programs?.find((program) => program.id === id)
  }, [formik.values.step2.items, index, programs])

  const hasRequirements =
    selectedProgramObj?.registrationRequirements != null && selectedProgramObj.registrationRequirements.length > 0

  useEffect(() => {
    if (hasRequirements) {
      void formik.setFieldValue(`step2.items.${index}.captions`, Array(5).fill(''))
      void formik.setFieldValue(`step2.items.${index}.files_uid`, Array(5).fill(''))
    } else {
      void formik.setFieldValue(`step2.items.${index}.captions`, null)
      void formik.setFieldValue(`step2.items.${index}.files_uid`, null)
    }
  }, [hasRequirements])

  const facultiesOptions = React.useMemo(() => {
    return faculties != null ? faculties.filter((n) => n).map((s) => ({ label: s.name, value: s.id })) : []
  }, [faculties])

  const programOptions = React.useMemo(() => {
    const selectedPrograms = formik.values.step2.items.map((item) => item.program)
    if (programs != null) {
      return programs.filter((p) => !selectedPrograms.includes(p.id)).map((s) => ({ label: s.name, value: s.id }))
    }
    return []
  }, [formik.values.step2.items, programs])

  return (
    <Section
      key={index}
      className="my-2 w-full rounded-card bg-card p-6 pt-4"
      icon={<ProgramsIcon />}
      title={t('program:faculty_program')}
      rightElement={
        <div className="flex w-full justify-end" title={t('common:delete')}>
          {index !== 0 && (
            <button
              onClick={() => void handleDelete(index, remove)}
              type="button"
              className="rounded-full p-2 hover:bg-hover-icon"
            >
              <DeleteIcon />
            </button>
          )}
        </div>
      }
    >
      <SelectInput
        name={`step2.items.${index}.faculty`}
        options={facultiesOptions}
        label={t('program:faculty')}
        required
      />
      <SelectInput
        name={`step2.items.${index}.program`}
        options={programOptions}
        label={t('program:programme')}
        disabled={programsValidating}
        isLoading={programsValidating}
        required
      />

      {hasRequirements && (
        <>
          <div
            className="master-requirements my-5 overflow-x-scroll break-words rounded-card bg-darkblueMessages py-3 pl-8 pr-4 text-primaryWhite"
            dangerouslySetInnerHTML={{ __html: selectedProgramObj.registrationRequirements }}
          />

          <Table.Table className="mt-[24px]">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  <div className="flex items-center">
                    <File />
                    <span className="ml-2">{t('file:attached_files')}</span>
                  </div>
                </Table.Th>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>#</Table.Th>
                <Table.Th className="!text-center">{t('file:file_name')}</Table.Th>
                <Table.Th className="flex items-center justify-center !text-center">{t('file:choose_file')}</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Files index={index} key={index} />
            </Table.Tbody>
          </Table.Table>
        </>
      )}
    </Section>
  )
}

function Files({ index }: { index: number }): JSX.Element | null {
  const formik = useFormikContext<Api.postMasterRegistrationParams['body']>()
  const id = `label-${React.useId()}`
  const [fileId] = React.useState(`${id}-${Date.now()}`)
  const items = formik.values.step2.items[index]

  const fileInputChange = React.useCallback(
    (files: readonly FileState[], name: string) => {
      if (files[0]?.remote.file != null && files[0].remote.status === 'DONE') {
        void formik.setFieldValue(name, files[0]?.remote.file.id)
      }
    },
    [formik]
  )

  if (items!.captions == null || items!.files_uid == null) return null
  return (
    <>
      {items!.captions.map((item, i) => (
        <Table.Tr key={i}>
          <Table.Td>{i + 1}</Table.Td>
          <Table.Td className="w-[45%]">
            <div className="mx-auto max-w-[300px]">
              <TextInput name={`step2.items.${index}.captions.${i}`} type="text" />
            </div>
          </Table.Td>
          <Table.Td className="mx-auto w-[45%]">
            <FileInput
              mediaFileType="registration"
              key={`${fileId}${i}`}
              config={UploadConfigMap.registration}
              onChange={(files) => fileInputChange(files, `step2.items.${index}.files_uid.${i}`)}
              name={`step2.items.${index}.files_uid.${i}`}
              label=""
              popover={false}
              className="mx-auto w-min"
            />
          </Table.Td>
        </Table.Tr>
      ))}
    </>
  )
}
