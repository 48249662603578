import { type FormikHelpers } from 'formik'
import { t as tt } from 'i18next'
import React, { useState } from 'react'
import * as Api from 'src/api'
import { LoudspeakerWhiteIcon } from 'src/assets/icons/customIcons/LoudspeakerWhite'
import { cacheValidation } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Loader from 'src/views/components/Loader'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import * as Yup from 'yup'
import ResultPage from './ResultPage'
import Step3 from './Step3'
import { SubmitButton } from './SubmitButton'

const MastersStep1 = React.lazy(async () => await import('../MastersRegistration/Step1'))
const MastersStep2 = React.lazy(async () => await import('../MastersRegistration/Step2'))
const LecturersStep1 = React.lazy(async () => await import('../LecturersRegistration/Step1'))
const LecturersStep2 = React.lazy(async () => await import('../LecturersRegistration/Step2'))

interface Props {
  onSubmit: (values: any, helpers: FormikHelpers<any>) => Promise<void>
  initialValues: any
  degree: 'lecturers' | 'masters'
  successfullySubmited: boolean
}

export default function RegistrationFactory({
  onSubmit,
  initialValues,
  degree,
  successfullySubmited,
}: Props): JSX.Element | null {
  const t = useTranslatable()

  return (
    <SuspenseWrapper
      title={degree === 'masters' ? t('registration:masters_registration') : t('registration:lecturers_registration')}
    >
      <PageContent
        onSubmit={onSubmit}
        initialValues={initialValues}
        degree={degree}
        successfullySubmited={successfullySubmited}
      />
    </SuspenseWrapper>
  )
}

function PageContent({ initialValues, onSubmit, degree, successfullySubmited }: Props): JSX.Element {
  const t = useTranslatable()
  const [step, setStep] = useState('step1')
  const locale = useLocale()
  const {
    data: { enabled },
    isValidating,
  } = useApi({
    endpoint: Api.getRegistrationCheckStatus,
    params: React.useMemo(
      () => ({
        headers: {
          'Accept-Language': locale,
        },
        args: { degree },
      }),
      [degree, locale]
    ),
  })

  const persNumValidation = React.useCallback(
    async (value?: string) => {
      try {
        if (value != null && value.length < 15 && value.length > 5) {
          await Api.postCheckPersonalNumber({
            headers: {
              'Accept-Language': locale,
            },
            body: {
              persNum: value,
            },
          })
        }
        return true
      } catch (error: any) {
        if (error.type === 'ErrorsObject') {
          return false
        }
        return true
      }
    },
    [locale]
  )

  const persNumValidationTest = cacheValidation(persNumValidation)

  const validationSchema = Yup.object({
    step1: Yup.object({
      pers_num: Yup.string()
        .min(5, tt('common:min_char', { number: 5, key: tt('person:personal_number') }))
        .max(15, tt('common:max_char', { number: 15, key: tt('person:personal_number') }))
        .test(
          'pers_num',
          t('registration:pers_num_exist'),

          persNumValidationTest
        ),
      mobile_phone1: Yup.string().min(14, tt('common:min_char', { number: 9, key: t('person:mobile_phone') })),
      mobile_phone2: Yup.string().min(14, tt('common:min_char', { number: 9, key: t('person:mobile_phone') })),
      home_phone: Yup.string().min(15, tt('common:min_char', { number: 10, key: t('person:home_phone') })),
      firstname: Yup.string()
        .matches(/^[ა-ჰ-]+$/, tt('common:format_is_invalid', { key: t('person:firstname') }))
        .min(2, tt('common:min_char', { number: 2, key: tt('person:firstname') })),
      lastname: Yup.string()
        .matches(/^[ა-ჰ-]+$/, tt('common:format_is_invalid', { key: t('person:lastname') }))
        .min(2, tt('common:min_char', { number: 2, key: t('person:lastname') })),
      firstnameLatin: Yup.string()
        .matches(/^[a-zA-Z-]+$/g, tt('common:format_is_invalid', { key: t('person:firstname') }))
        .min(2, tt('common:min_char', { number: 2, key: tt('person:firstname') })),
      lastnameLatin: Yup.string()
        .matches(/^[a-zA-Z-]+$/g, tt('common:format_is_invalid', { key: t('person:lastname') }))
        .min(2, tt('common:min_char', { number: 2, key: t('person:lastname') })),
      personal_email: Yup.string().email(t('common:email_invalid')),
      legal_address: Yup.string()
        .min(6, tt('common:min_char', { number: 6, key: t('person:juridical_address') }))
        .max(1000, tt('common:max_char', { number: 1000, key: t('person:juridical_address') })),
      actual_address: Yup.string()
        .min(6, tt('common:min_char', { number: 6, key: t('person:actual_address') }))
        .max(1000, tt('common:max_char', { number: 1000, key: t('person:actual_address') })),
    }),
  })

  if (isValidating) {
    return <Loader className="m-auto flex" />
  } else if (!enabled)
    return (
      <div className="mb-[30px] flex items-center justify-center rounded-card bg-darkblueMessages px-4 py-3">
        <div className="flex min-h-[48px] min-w-[48px] items-center justify-center rounded-full bg-[#4e536b]">
          <LoudspeakerWhiteIcon />
        </div>
        <span className="mx-6 w-full text-white">{t('registration:disabled')}</span>
      </div>
    )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      isConfirmable={!successfullySubmited}
      validationSchema={validationSchema}
    >
      {successfullySubmited ? (
        <ResultPage />
      ) : (
        <>
          <FormError />
          <div className={`${step === 'step1' ? 'block' : 'hidden'}`}>
            <SuspenseWrapper>{degree === 'lecturers' ? <LecturersStep1 /> : <MastersStep1 />}</SuspenseWrapper>
          </div>
          <div className={`${step === 'step2' ? 'block' : 'hidden'}`}>
            <SuspenseWrapper>{degree === 'lecturers' ? <LecturersStep2 /> : <MastersStep2 />}</SuspenseWrapper>
          </div>
          <div className={`${step === 'step3' ? 'block' : 'hidden'}`}>
            <Step3 />
          </div>
          <SubmitButton setStep={setStep} step={step} />
        </>
      )}
    </Form>
  )
}
