export function ProgramsIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="programs_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M9-326H3a2,2,0,0,1-2-2v-3.5H2v3.5a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1v-3.5h1v3.5A2,2,0,0,1,9-326Z"
          transform="translate(43 357)"
          fill="gray"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M49.005,27.508a5.086,5.086,0,0,1-1.816-.325c-.738-.3-4.531-1.937-5.266-2.325a1.811,1.811,0,0,1-.91-1.55,1.691,1.691,0,0,1,.9-1.524c.942-.535,4.305-2.436,5.227-2.937a3.885,3.885,0,0,1,1.828-.458,3.54,3.54,0,0,1,1.778.464c.378.219,1.173.648,2.014,1.1,1.393.75,2.833,1.526,3.317,1.848a1.993,1.993,0,0,1,.883,1.766,1.55,1.55,0,0,1-.957,1.3c-.419.186-1.243.54-2.116.916-1.227.527-2.617,1.125-3.183,1.385A4.1,4.1,0,0,1,49.005,27.508Zm-.041-8.119a2.868,2.868,0,0,0-1.35.336c-.916.5-4.271,2.395-5.211,2.929a.7.7,0,0,0-.39.639.81.81,0,0,0,.377.681c.656.346,4.355,1.955,5.171,2.281a4.112,4.112,0,0,0,1.445.254,3.1,3.1,0,0,0,1.277-.244c.578-.266,1.974-.866,3.206-1.4.87-.374,1.692-.727,2.105-.911a.549.549,0,0,0,.367-.475,1,1,0,0,0-.441-.849c-.445-.3-1.993-1.13-3.237-1.8-.849-.457-1.651-.889-2.042-1.117A2.54,2.54,0,0,0,48.964,19.389Z"
          fill="gray"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M55.5,28.5A.5.5,0,0,1,55,28V24.784l-2.862-1.631a.5.5,0,1,1,.5-.869L56,24.2V28A.5.5,0,0,1,55.5,28.5Z"
          fill="gray"
        />
      </g>
    </svg>
  )
}
