export function LoudspeakerWhiteIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="26.596"
      height="23.704"
      viewBox="0 0 26.596 23.704"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_12"
            data-name="Path 12"
            d="M333.023,164.734h10.928v-2.317l-2.132-.859-6.422-2.641-2.374-.98Z"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g id="Loudspeaker_Icon" data-name="Loudspeaker Icon" transform="translate(-361.422 47.052) rotate(-30)">
        <g id="Group_24" data-name="Group 24" transform="translate(-1 0)">
          <g
            id="Rectangle_3649"
            data-name="Rectangle 3649"
            transform="translate(330 153)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          >
            <rect width="4" height="6" stroke="none" />
            <rect x="0.5" y="0.5" width="3" height="5" fill="none" />
          </g>
          <g
            id="Rectangle_3651"
            data-name="Rectangle 3651"
            transform="translate(345.491 146.871)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          >
            <rect width="4" height="18" stroke="none" />
            <rect x="0.5" y="0.5" width="3" height="17" fill="none" />
          </g>
          <g id="Mask_Group_6" data-name="Mask Group 6" clipPath="url(#clip-path)">
            <g
              id="Ellipse_385"
              data-name="Ellipse 385"
              transform="translate(335 157)"
              fill="none"
              stroke="#fff"
              strokeWidth="1"
            >
              <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
              <circle cx="3.5" cy="3.5" r="3" fill="none" />
            </g>
          </g>
          <rect
            id="Rectangle_3652"
            data-name="Rectangle 3652"
            width="4"
            height="1"
            rx="0.5"
            transform="translate(353 156)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3653"
            data-name="Rectangle 3653"
            width="4"
            height="1"
            rx="0.5"
            transform="translate(352.232 153.561) rotate(-45)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3654"
            data-name="Rectangle 3654"
            width="4"
            height="1"
            rx="0.5"
            transform="translate(352.939 158.732) rotate(45)"
            fill="#fff"
          />
          <g id="Path_11" data-name="Path 11" transform="translate(333 153)" fill="none">
            <path d="M0,0,12.507-4.879h1v16h-1L0,6Z" stroke="none" />
            <path
              d="M 12.50671672821045 -3.805610656738281 L 0.9999971389770508 0.6832876205444336 L 0.9999971389770508 5.328873634338379 L 12.50671672821045 10.0404052734375 L 12.50671672821045 -3.805610656738281 M 12.50671672821045 -4.879013061523438 L 13.50671672821045 -4.879013061523438 L 13.50671672821045 11.12098693847656 L 12.50671672821045 11.12098693847656 L -2.86102294921875e-06 5.999997138977051 L -2.86102294921875e-06 -2.86102294921875e-06 L 12.50671672821045 -4.879013061523438 Z"
              stroke="none"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
