import React from 'react'
import { useMount } from 'react-use'
import * as Api from 'src/api'
import { type Survey, type SurveyProgress } from 'src/api'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth, type AuthenticatedAuthState } from 'src/hooks/auth/authObservableValue'

export interface Params {
  readonly survey: Survey
  readonly defaultPage?: number
  readonly setPage: (page: 'start' | 'questions' | 'end') => void
}

export interface State {
  readonly page: number
  readonly isLoading: boolean
  readonly data: readonly Api.SurveyQuestion[] | null
  readonly progress: SurveyProgress
}

export interface Values {
  readonly [k: string]: readonly Api.UserAnswerOption[] | undefined
}

export interface Return extends State {
  readonly updatePage: (page: number) => Promise<void>
  readonly onSubmit: (values: Values) => Promise<void>
}

export function useQuestionsPage({ survey, defaultPage, setPage }: Params): Return {
  const headers = useAuthenticatedHeaders()

  const [state, setState] = React.useState<State>({
    progress: survey.SurveyProgress,
    page: defaultPage ?? 1,
    isLoading: true,
    data: null,
  })
  const auth = useAuth() as AuthenticatedAuthState

  async function updatePage(page: number): Promise<void> {
    try {
      if (page < 1) {
        setPage('start')
      } else if (page <= Math.ceil(survey.questionsCount / survey.perPage)) {
        setState((state) => ({ ...state, isLoading: true }))

        if (auth.profile?.type === 'UserProfileStudent') {
          const data = await Api.getStudentSurveyQuestions({
            headers,
            args: {
              id: survey.id,
            },
            query: {
              page,
            },
          })
          setState((state) => ({ ...state, data, page, isLoading: false }))
        } else if (auth.profile?.type === 'UserProfileLecturer') {
          const data = await Api.getLecturerSurveyQuestions({
            headers,
            args: {
              id: survey.id,
            },
            query: {
              page,
            },
          })

          setState((state) => ({ ...state, data, page, isLoading: false }))
        }
      } else {
        setPage('end')
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function onSubmit(values: Values): Promise<void> {
    try {
      setState((state) => ({ ...state, isLoading: true }))

      if (auth.profile?.type === 'UserProfileStudent') {
        const progress = await Api.postStudentSurveyQuestionsAnswers({
          headers,
          body: {
            data: state.data!.map((q) => ({
              questionId: parseInt(q.id),
              answers: values[`field-${q.id}`] ?? null,
            })),
          },
          args: {
            id: survey.id,
          },
        })
        setState((state) => ({ ...state, progress }))
      } else if (auth.profile?.type === 'UserProfileLecturer') {
        const progress = await Api.postLecturerSurveyQuestionsAnswers({
          headers,
          body: {
            data: state.data!.map((q) => ({
              questionId: parseInt(q.id),
              answers: values[`field-${q.id}`] ?? null,
            })),
          },
          args: {
            id: survey.id,
          },
        })

        setState((state) => ({ ...state, progress }))
      }

      void updatePage(state.page + 1)
    } catch (err) {
      console.error(err)
    }
  }

  useMount(() => {
    void updatePage(state.page)
  })

  return { ...state, updatePage, onSubmit }
}
