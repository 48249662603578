import { Link, Outlet } from 'react-router-dom'
import { EngIcon } from 'src/assets/icons/customIcons/Eng'
import { GeoIcon } from 'src/assets/icons/customIcons/Geo'
import { useAuth } from 'src/hooks/auth/authObservableValue'
import { useLocale } from 'src/hooks/locale/locale'
import { useLocaleLinks, useTranslatable } from 'src/hooks/locale/utils'
import Footer from 'src/tailwind/layout/Footer'
import ArgusLogo from 'src/views/includes/ArgusLogo'
import AuthenticatedLayout from 'src/views/includes/AuthenticatedLayout'
import AppLoadingPage from './Undetermined/AppLoadingPage'

export default function UniversalLayout(): JSX.Element {
  const locale = useLocale()
  const localeLinks = useLocaleLinks()
  const t = useTranslatable()
  const auth = useAuth()

  if (auth.state === 'Undetermined') {
    return <AppLoadingPage />
  }
  if (auth.state === 'Authenticated') {
    return <AuthenticatedLayout />
  }
  return (
    <>
      <header className="flex h-20 w-full items-center justify-between border-b-DEFAULT border-[#E6E6E6] bg-primaryWhite pl-[3px] pr-[10px] md:pl-[18px] md:pr-[30px] xl:pl-[28px] xl:pr-[40px]">
        <ArgusLogo />
        <div className="cursor-pointer rounded-[18px] p-2 hover:bg-card" title={t('common:change_language')}>
          <Link to={locale === 'en' ? localeLinks.ka : localeLinks.en}>
            <div className="flex gap-[6px] text-primaryTextColor">
              {locale === 'en' ? (
                <>
                  <GeoIcon />
                  <span>{t('common:georgian')}</span>
                </>
              ) : (
                <>
                  <EngIcon />
                  <span>{t('common:english')}</span>
                </>
              )}
            </div>
          </Link>
        </div>
      </header>
      <div className="mx-auto flex h-[calc(100vh-80px)] w-full max-w-[2000px] flex-col justify-between overflow-scroll bg-primaryWhite px-[15px] py-[4%] md:px-[30px] xl:p-[40px]">
        <Outlet />
        <Footer />
      </div>
    </>
  )
}
