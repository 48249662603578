export function AchievmentIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="achievment_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(43 17)" fill="none" stroke="gray" strokeWidth="1">
          <circle cx="6" cy="6" r="6" stroke="none" />
          <circle cx="6" cy="6" r="5.5" fill="none" />
        </g>
        <path
          id="Polygon_1"
          data-name="Polygon 1"
          d="M3.81.564a.2.2,0,0,1,.379,0l.766,2.279a.2.2,0,0,0,.184.136l2.317.062a.2.2,0,0,1,.12.356l-1.856,1.5a.2.2,0,0,0-.067.21l.662,2.344a.2.2,0,0,1-.31.216L4.117,6.3a.2.2,0,0,0-.234,0L1.994,7.663a.2.2,0,0,1-.31-.216L2.346,5.1a.2.2,0,0,0-.067-.21L.423,3.4a.2.2,0,0,1,.12-.356L2.86,2.979a.2.2,0,0,0,.184-.136Z"
          transform="translate(45 18.6)"
          fill="gray"
        />
        <path
          id="Subtraction_6"
          data-name="Subtraction 6"
          d="M0,5H0V0H1.01V1H1V3.523l1.5-.6,1.5.6V1H3.995V0H5V5L2.5,4,0,5Z"
          transform="translate(44.5 26.177) rotate(30)"
          fill="gray"
        />
        <path
          id="Subtraction_7"
          data-name="Subtraction 7"
          d="M0,0H0V5H1.01V4H1V1.477l1.5.6,1.5-.6V4H3.995V5H5V0L2.5,1,0,0Z"
          transform="translate(56.034 30.507) rotate(150)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
