export function CatalogIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="catalog_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <g
          id="Rectangle_15"
          data-name="Rectangle 15"
          transform="translate(41 17)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="12" height="13" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="11" height="12" rx="1.5" fill="none" />
        </g>
        <path
          id="Subtraction_1"
          data-name="Subtraction 1"
          d="M14-278H6a2,2,0,0,1-2-2v-2H5v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1v-10a1,1,0,0,0-1-1H11v-1h3a2,2,0,0,1,2,2v10A2,2,0,0,1,14-278Z"
          transform="translate(41 311)"
          fill="gray"
        />
        <rect
          id="Rectangle_17"
          data-name="Rectangle 17"
          width="6"
          height="1"
          rx="0.5"
          transform="translate(44 20)"
          fill="gray"
        />
        <rect
          id="Rectangle_18"
          data-name="Rectangle 18"
          width="6"
          height="1"
          rx="0.5"
          transform="translate(44 23)"
          fill="gray"
        />
        <rect
          id="Rectangle_19"
          data-name="Rectangle 19"
          width="6"
          height="1"
          rx="0.5"
          transform="translate(44 26)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
