import * as React from 'react'
import { LoudspeakerWhiteIcon } from 'src/assets/icons/customIcons/LoudspeakerWhite'
import { useAuth } from 'src/hooks/auth/authObservableValue'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'

export default function RegimeMessageAlert(): React.ReactElement | null {
  const userState = useUserState()
  const t = useTranslatable()
  const auth = useAuth()

  if (auth.state === 'Authenticated' && (userState.data?.regime?.functionalIsLimited ?? false)) {
    return (
      <div className="mb-[30px] flex items-center justify-center rounded-card bg-[#ef9d0fdc] px-4 py-3 print:hidden">
        <div className="flex min-h-[48px] min-w-[48px] items-center justify-center rounded-full bg-[#EF9B0F]">
          <LoudspeakerWhiteIcon />
        </div>
        <span className="mx-6 w-full text-white">
          {userState.data?.regime?.message != null ? (
            <div dangerouslySetInnerHTML={{ __html: userState.data?.regime?.message }} />
          ) : (
            <>
              {t('common:old_argus_alert')}
              <a className="ml-1 text-[#3226dd]" href="https://argus.iliauni.edu.ge/">
                https://argus.iliauni.edu.ge
              </a>
            </>
          )}
        </span>
      </div>
    )
  }

  return null
}
