import * as React from 'react'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import Swal from 'sweetalert2'

export default function RegimeChangeAlert(): React.ReactElement | null {
  const userState = useUserState()
  const prevUserState = React.useRef<typeof userState>()
  const t = useTranslatable()
  const locale = useLocale()

  React.useEffect(() => {
    void (async () => {
      if (prevUserState.current == null) {
        prevUserState.current = userState

        return
      }

      if (prevUserState.current.data == null) return
      if (userState.data == null) return
      if (prevUserState.current.data.regime.id === userState.data.regime.id) return

      await Swal.fire({
        title: t('system:regime_has_changed'),
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: '#a83032',
        confirmButtonText: t('common:return_to_homepage'),
      })

      location.href = `/${locale}`
    })()
  })

  return null
}
