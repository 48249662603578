export function MycoursesIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="mycourses_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="gray"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="gray"
          opacity="0"
        />
        <circle id="Ellipse_1" data-name="Ellipse 1" cx="1" cy="1" r="1" transform="translate(42 24.5)" fill="gray" />
        <circle id="Ellipse_2" data-name="Ellipse 2" cx="1" cy="1" r="1" transform="translate(42 20.5)" fill="gray" />
        <circle id="Ellipse_3" data-name="Ellipse 3" cx="1" cy="1" r="1" transform="translate(42 28.5)" fill="gray" />
        <rect
          id="Rectangle_12"
          data-name="Rectangle 12"
          width="10"
          height="1"
          rx="0.5"
          transform="translate(46 25)"
          fill="gray"
        />
        <rect
          id="Rectangle_13"
          data-name="Rectangle 13"
          width="10"
          height="1"
          rx="0.5"
          transform="translate(46 21)"
          fill="gray"
        />
        <rect
          id="Rectangle_14"
          data-name="Rectangle 14"
          width="10"
          height="1"
          rx="0.5"
          transform="translate(46 29)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
