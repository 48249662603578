export function LoudspeakerIcon(): JSX.Element {
  return (
    <svg id="louspeaker_icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_3617" data-name="Rectangle 3617" width="24" height="24" fill="#d6d6d6" opacity="0" />
      <rect
        id="Rectangle_3618"
        data-name="Rectangle 3618"
        width="22"
        height="22"
        transform="translate(1 1)"
        fill="#d6d6d6"
        opacity="0"
      />
      <g id="Group_27" data-name="Group 27" transform="translate(-889 -28)">
        <path
          id="Path_26"
          data-name="Path 26"
          d="M899.416,31.97l-6.923,8.584-1.915,1.167,2.029,3.5,1.978-1.128L905.6,42.583l.241.494L907.9,41.9l-6.834-12.008-2.079,1.281Z"
          transform="translate(0.207 2.169)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M907.928,43.472,901.3,32.188"
          transform="translate(-1.813 1.736)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M893.125,42.938l2.054,3.516"
          transform="translate(-0.273 -0.291)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        />
        <path
          id="Path_29"
          data-name="Path 29"
          d="M902.914,46.353a2.373,2.373,0,0,1-2.308,2.522,2.333,2.333,0,0,1-2.489-1.846"
          transform="translate(-1.214 -0.935)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        />
      </g>
      <g id="Group_28" data-name="Group 28" transform="translate(-889 -29)">
        <rect
          id="Rectangle_3655"
          data-name="Rectangle 3655"
          width="4"
          height="1"
          rx="0.5"
          transform="translate(906.794 37.362) rotate(-30)"
          fill="gray"
        />
        <rect
          id="Rectangle_3656"
          data-name="Rectangle 3656"
          width="4"
          height="1"
          rx="0.5"
          transform="translate(904.91 35.633) rotate(-75)"
          fill="gray"
        />
        <rect
          id="Rectangle_3657"
          data-name="Rectangle 3657"
          width="4"
          height="1"
          rx="0.5"
          transform="translate(908.108 39.758) rotate(15)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
