export function SurveyIcon(): JSX.Element {
  return (
    <svg id="stats_icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_3617" data-name="Rectangle 3617" width="24" height="24" fill="#d6d6d6" opacity="0" />
      <rect
        id="Rectangle_3618"
        data-name="Rectangle 3618"
        width="22"
        height="22"
        transform="translate(1 1)"
        fill="#d6d6d6"
        opacity="0"
      />
      <g
        id="Rectangle_3670"
        data-name="Rectangle 3670"
        transform="translate(2 2)"
        fill="none"
        stroke="gray"
        strokeWidth="1"
      >
        <rect width="20" height="20" rx="4" stroke="none" />
        <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="none" />
      </g>
      <rect
        id="Rectangle_3671"
        data-name="Rectangle 3671"
        width="1"
        height="4"
        rx="0.5"
        transform="translate(7 13)"
        fill="gray"
      />
      <rect
        id="Rectangle_3672"
        data-name="Rectangle 3672"
        width="1"
        height="10"
        rx="0.5"
        transform="translate(10 7)"
        fill="gray"
      />
      <rect
        id="Rectangle_3673"
        data-name="Rectangle 3673"
        width="1"
        height="7"
        rx="0.5"
        transform="translate(13 10)"
        fill="gray"
      />
      <rect
        id="Rectangle_3674"
        data-name="Rectangle 3674"
        width="1"
        height="4"
        rx="0.5"
        transform="translate(16 13)"
        fill="gray"
      />
    </svg>
  )
}
