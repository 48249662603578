import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { type UserAnswerOption } from 'src/api'
import * as SurveyTable from 'src/tailwind/components/SurveyTable'
import Select from 'src/views/components/Select'
import * as Yup from 'yup'

interface Option {
  readonly id: number
  readonly title: string // გამოდის ჩეკბოქსის ლეიბლად
  readonly customAllowed: boolean
  readonly fcolor: string
  readonly bcolor: string
}

interface Criterias {
  readonly id: number
  readonly title: string // გამოდის თავზე ჰორიზონტალურ ხაზში
  readonly fcolor: string
  readonly bcolor: string
}

interface DropdownsType {
  readonly id: number
  readonly type: number
  readonly title: string
  readonly required: boolean
  readonly fcolor: string
  readonly bcolor: string
  readonly options: readonly Option[]
  readonly criterias: readonly Criterias[]
}

interface checkCriterias {
  readonly criteriaId: number
}
interface QuestionTypeDropdownsValue {
  readonly optionId: number
  readonly criterias: readonly checkCriterias[]
}

interface TypeDropdownsProps {
  readonly question: DropdownsType
  readonly name: string
  readonly index: number
}

export function SurveyDropdownSchema(message: string, isRequired: boolean, optionsLength: number): Yup.Schema {
  if (!isRequired) return Yup.mixed()
  return Yup.array(Yup.object()).min(optionsLength, message).required(message)
}

export default function SurveyDropdownInput({ question, name, index }: TypeDropdownsProps): React.ReactElement {
  const { errors, isValid, submitCount } = useFormikContext<{ [k: string]: readonly UserAnswerOption[] | undefined }>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<undefined | readonly QuestionTypeDropdownsValue[]>({ name })

  const values = React.useRef(
    React.useMemo(() => {
      return Object.fromEntries(
        question.options.map((o) => {
          return [`option-${o.id}`, field.value?.find((v) => v.optionId === o.id)?.criterias[0]?.criteriaId ?? null]
        })
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  function updateValue(): void {
    void helpers.setValue(
      question.options.flatMap((option) => {
        const criteriaId = values.current[`option-${option.id}`]

        if (criteriaId != null) {
          return {
            optionId: option.id,
            criterias: [
              {
                criteriaId,
              },
            ],
          }
        }

        return []
      })
    )
  }

  return (
    <div>
      <div className="flex">
        <div className="mr-2">{index}.</div>
        <div className="font-bold">
          {question.title}
          {question.required && <span className="pl-1 text-primaryRed">*</span>}
        </div>
      </div>
      <SurveyTable.SurveyTable>
        <SurveyTable.Tbody className="p-[12px]">
          {question.options.map((option) => (
            <SurveyTable.Tr className="flex items-center justify-between border-0" key={option.id}>
              <SurveyTable.Td className="px-[12px]">{option.title}</SurveyTable.Td>
              <SurveyTable.Td>
                <div>
                  <Select
                    className="w-full min-w-[250px] rounded-[5px] border-borderColor text-sm"
                    options={question.criterias.map((c) => ({ value: c.id, label: c.title }))}
                    defaultValue={question.criterias.map((c) => {
                      if (c.id === values.current[`option-${option.id}`]) {
                        return { value: c.id, label: c.title }
                      } else {
                        return null
                      }
                    })}
                    isClearable
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    onChange={(e) => {
                      values.current[`option-${option.id}`] = e?.value ?? null

                      updateValue()
                    }}
                  />
                </div>
              </SurveyTable.Td>
            </SurveyTable.Tr>
          ))}
        </SurveyTable.Tbody>
      </SurveyTable.SurveyTable>
      <div className="h-[30px] px-[12px] py-0 text-sm text-primaryRed">
        {!isValid && submitCount > 0 ? errors[`field-${question.id}`] : null}
      </div>
    </div>
  )
}
