import classnames from 'classnames'
import { type Survey } from 'src/api'
import { useTranslatable } from 'src/hooks/locale/utils'
import type * as survey from '../../hooks/survey'

interface Props {
  readonly isLoading: boolean
  readonly setPage: survey.Return['setPage']
  readonly survey: Survey
}

export const StartPage = ({ setPage, survey, isLoading }: Props): JSX.Element => {
  const t = useTranslatable()

  return (
    <>
      <div
        className="mt-2"
        style={{
          overflowY: 'auto',
          opacity: isLoading ? 0.5 : undefined,
          pointerEvents: isLoading ? 'none' : undefined,
        }}
      >
        <div className="text-caption" dangerouslySetInnerHTML={{ __html: survey.text }} />
      </div>
      <div className="mt-[24px]" style={{ borderTop: '1px solid #E6E6E6' }}>
        <div className="mt-[24px] flex justify-end">
          <button
            className={classnames(
              'rounded bg-seconderyRed px-[16px] py-[14px] text-title text-white hover:bg-seconderyRed/90',
              {
                disabled: isLoading,
              }
            )}
            onClick={() => setPage('questions')}
          >
            {t('survey:start_survey')}
          </button>
        </div>
      </div>
    </>
  )
}
