import { format } from 'date-fns'
import { type FormikHelpers } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import RegistrationFactory from '../Components/RegistrationFactory'

const initialValues: Api.postMasterRegistrationParams['body'] = {
  step1: {
    pers_num: '',
    birth_date: null,
    firstname: '',
    lastname: '',
    firstname_en: '',
    lastname_en: '',
    personal_email: '',
    persdoc_exp_date: null,
    persdoc_uid: '',
    gender: '',
    army_status_doc_uid: '',
    citizenship: '',
    photo_uid: '',
    legal_address: '',
    actual_address: '',
    mobile_phone1: '',
    mobile_phone2: '',
    home_phone: '',
    high_school: '',
    high_school_doc_type: '',
    high_school_finish_date: null,
    high_school_doc_num: '',
    high_school_doc_date: null,
    high_school_doc_uid: '',
    major_degree: '',
    minor_degree: '',
    exam_identity_code: '',
    exam_card_uid: '',
    gpa: '',
    has_limited_abilities: '',
    limited_abilities_text: '',
  },
  step2: {
    testtype: '',
    items: [
      {
        faculty: '',
        program: '',
        captions: null,
        files_uid: null,
      },
    ],
  },
  step3: {
    agreement: false,
  },
}

export default function MastersRegistration(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const t = useTranslatable()
  const locale = useLocale()

  const onSubmit = React.useCallback(
    async (
      values: Api.postMasterRegistrationParams['body'],
      helpers: FormikHelpers<Api.postMasterRegistrationParams['body']>
    ): Promise<void> => {
      const result = await Api.postMasterRegistration({
        headers: {
          'Accept-Language': locale,
        },
        body: {
          ...values,
          step1: {
            ...values.step1,
            birth_date: format(new Date(values.step1.birth_date!), 'dd/MM/yyyy'),
            persdoc_exp_date: format(new Date(values.step1.persdoc_exp_date!), 'dd/MM/yyyy'),
            high_school_finish_date: format(new Date(values.step1.high_school_finish_date!), 'dd/MM/yyyy'),
            high_school_doc_date: format(new Date(values.step1.high_school_doc_date!), 'dd/MM/yyyy'),
          },
        },
      })
      void helpers.setFieldValue('result', result)
      toast.success(t('registration:registration_success'))
      setSuccessfullySubmited(true)
    },
    [locale, t]
  )

  return (
    <RegistrationFactory
      degree="masters"
      onSubmit={onSubmit}
      initialValues={initialValues}
      successfullySubmited={successfullySubmited}
    />
  )
}
