import classNames from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import PolygonIcon from 'src/assets/icons/customIcons/Polygon'
import { Unselect } from 'src/assets/icons/customIcons/Unselect'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/authObservableValue'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useRechooseState } from 'src/hooks/rechoose'
import { useActiveSemester } from 'src/hooks/semesters'
import { useUserState } from 'src/hooks/userState'
import { ErrorBoundary } from 'src/views/components/Error'
import { useSWRConfig } from 'swr'
import Loader from '../components/Loader'

export function Rechoose(): React.ReactElement {
  return (
    <React.Suspense fallback={null}>
      <ErrorBoundary errorElement={null}>
        <PageContent />
      </ErrorBoundary>
    </React.Suspense>
  )
}

function PageContent(): React.ReactElement | null {
  const auth = useAuth()
  const t = useTranslatable()
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const userState = useUserState()
  const [close, setClose] = React.useState(false)
  const response = useApi({
    endpoint: Api.getStudentChoicesRechoose,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
    shouldFetch:
      auth.profile?.type === 'UserProfileStudent' && userState.data != null && userState.data.regime.chooseAllowed,
  })
  const choice = response.data

  const activeSemester = useActiveSemester()
  const [buttonHover, setButtonHover] = React.useState(false)
  const [active, setActive] = React.useState(false)

  const [{ inFlight }, setRechooseState] = useRechooseState()
  const setInFlight = React.useCallback(
    (fn: (state: number) => number): void => setRechooseState(({ inFlight }) => ({ inFlight: fn(inFlight) })),
    [setRechooseState]
  )
  const submitting = response.isLoading || response.isValidating || inFlight > 0
  const swr = useSWRConfig()
  const DELETE_RECHOOSE = React.useCallback(
    async (params: { courseId: string; groupId: string }) => {
      setInFlight((n) => n + 1)

      try {
        await Api.deleteStudentChoicesRechoose({
          headers,
          body: {
            courseId: params.courseId,
            groupId: params.groupId,
          },
        })
        toast.success(t('common:updated_successfully'))
        window.scroll(0, 0)
        void swr.mutate(Api.getStudentCourseGroupSchedule)
        void swr.mutate(Api.getStudentChoicesRechoose)
        setClose(true)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }

      setInFlight((a) => a - 1)
    },
    [headers, setInFlight, swr, t]
  )

  if (choice == null) return null

  return (
    <div
      className={classNames(
        'fixed bottom-[120px] right-0 z-50 flex h-[200px] rounded-r-[8px] duration-500 ease-in-out',
        {
          'right-[-350px] sm:right-[-399px]': !active,
          'right-[5px]': active,
          'hidden opacity-100': close,
        }
      )}
      data-testid="rechooseModal"
    >
      <div
        onClick={() => setActive(!active)}
        onMouseEnter={() => setButtonHover(true)}
        onMouseLeave={() => setButtonHover(false)}
        className={classNames(
          'group right-[445px] flex h-full w-[34px] cursor-pointer items-center justify-center rounded-l-[8px] bg-[#454a64] duration-100 ease-in-out hover:w-[45px]'
        )}
        data-testid="modalRechooseButton"
      >
        <PolygonIcon fill={buttonHover ? '#FFD76A' : '#fff'} opacity={buttonHover ? '1' : '0.3'} />
      </div>
      <div
        className={classNames('flex w-[350px] flex-col gap-[24px] rounded-r-[8px] bg-[#454a64] p-[12px] sm:w-[400px]')}
      >
        <div className="flex flex-col gap-[10px]">
          <div className="w-full rounded-[12px] bg-[#585c74] text-sm">
            <p className="ml-[12px] text-[#a2a5b2]" data-testid="rechooseSemester">
              {activeSemester?.name}
            </p>{' '}
          </div>
          <p className="font-semibold text-white">{t('course:rechoose_course')}</p>
          <Link to={`/${locale}/student/courses/${choice.course!.id}/groups`} data-testid="rechooseCourseLink">
            <p className="text-[#a2a5b2]">{choice.course!.fullName}</p>
          </Link>
          <p className="text-[#a2a5b2]" data-testid="rechooseGroup">
            {choice.group?.name}
          </p>
        </div>
        <button
          onClick={() =>
            void DELETE_RECHOOSE({
              courseId: choice.course!.id,
              groupId: choice.group!.id,
            })
          }
          className="h-[40px] rounded-[4px] bg-yellow-400 font-semibold"
          disabled={submitting}
          data-testid="cancelRechooseButton"
        >
          <div className="flex items-center justify-center">
            <span className="mr-2 flex">{submitting ? <Loader className="size-[20px]" /> : <Unselect />}</span>
            {t('choice:cancel_rechoose')}
          </div>
        </button>
      </div>
    </div>
  )
}
