import * as React from 'react'
import { useAuth } from 'src/hooks/auth/auth'
import { useTranslatable } from 'src/hooks/locale/utils'
import { getNotificationsState, requestNotificationsPermission } from 'src/state/notifications/Notifications'
import { notificationsPermissionDenyDateStorage } from 'src/state/notifications/NotificationsPermissionDenyDate'
import { usePermissionModal } from 'src/state/notifications/NotificationsPermissionModal'
import { Button } from 'src/tailwind/components/Button'
import { Modal } from 'src/tailwind/components/Modal'
import instruction from 'src/videos/notifications-instruction.mp4'

export default function NotificationPermissionModal(): React.ReactElement | null {
  const [showVideo, setShowVideo] = React.useState<boolean>(false)
  const auth = useAuth()
  const t = useTranslatable()
  const modalShow = usePermissionModal()

  if (getNotificationsState() === 'granted' || auth.state !== 'Authenticated' || !modalShow.isOpen) {
    return null
  }

  async function grantPermission(): Promise<void> {
    if (getNotificationsState() !== 'denied') {
      const result = await requestNotificationsPermission()
      if (result !== 'denied') {
        notificationsPermissionDenyDateStorage.setValue(null)
      } else {
        notificationsPermissionDenyDateStorage.setValue(new Date().getTime())
      }
      modalShow.handleClose(false)
    } else {
      setShowVideo(true)
    }
  }

  return (
    <Modal
      isModalOpen={modalShow.isOpen !== null}
      onClose={() => modalShow.handleClose()}
      title={t('notification:notification_settings')}
      testId="NotificationPermissionModal"
      width={500}
      footer={
        <>
          {!showVideo && (
            <Button className="mr-2" variant="blue" onClick={() => void grantPermission()}>
              {t('notification:allow_browser_notifications')}
            </Button>
          )}
          <Button
            variant="red"
            onClick={() => void modalShow.handleClose()}
            data-testid="NotificationPermissionModal/close"
          >
            {t('common:close')}
          </Button>
        </>
      }
    >
      {!showVideo ? (
        <p>{t('notification:in_order_to_get_notifications_on_desktop_you_need_to_allow_this_setting')}</p>
      ) : (
        <>
          <p>
            {t(
              'notification:you_need_to_allow_browser_to_get_new_notifications_please_follow_instructions_shown_on_video'
            )}
          </p>

          <video className="max-w-full" autoPlay loop muted src={instruction} />
        </>
      )}
    </Modal>
  )
}
