import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import BackIcon from 'src/assets/icons/customIcons/BackIcon'
import { useApi } from 'src/helpers/hooks'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function FaqQuestion(): JSX.Element {
  return (
    <SuspenseWrapper>
      <PageContent />
    </SuspenseWrapper>
  )
}

function PageContent(): JSX.Element | null {
  const locale = useLocale()
  const { id } = useParams()
  const t = useTranslatable()
  const {
    data: { data },
  } = useApi({
    endpoint: Api.getFaqQuestion,
    params: React.useMemo(
      () => ({
        headers: {
          'Accept-Language': locale,
        },
        args: {
          id: id!,
        },
      }),
      [id, locale]
    ),
  })

  return (
    <Section
      title={t('faq:question')}
      icon={<FontAwesomeIcon icon={ProRegularSvgIcons.faSealQuestion} size="2xl" />}
      rightElement={
        <Link
          className="group relative mt-2 flex w-[150px] items-center justify-end rounded-full px-4 py-2 text-primaryTextColor duration-150 ease-in-out hover:bg-hover-card xxs:w-[60px] xxs:px-0 xs:w-[60px] xs:px-0"
          type="button"
          to="/faq"
        >
          <span className="absolute left-3 top-1/2 -translate-y-1/2 duration-150 ease-in-out group-hover:left-2 xxs:left-0 xs:left-0">
            <BackIcon />{' '}
          </span>
          {t('faq:all_questions')}
        </Link>
      }
    >
      <h2 className="mb-4">{data.question}</h2>
      <div dangerouslySetInnerHTML={{ __html: data.answer }} className="overflow-x-scroll py-4" />
    </Section>
  )
}
