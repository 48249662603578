import * as ProLightSvgIcons from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { ArrowRight } from 'src/assets/icons/customIcons/ArrowRight'
import { Courselist } from 'src/assets/icons/customIcons/Courselist'
import { useAllSearchParams, useApi } from 'src/helpers/hooks'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import NoContent from 'src/views/components/NoContent'
import Pagination from 'src/views/components/Pagination'
import { Form } from 'src/views/components/forms/formik/Form'
import TextInput from 'src/views/components/forms/formik/TextInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function Faq(): JSX.Element {
  const t = useTranslatable()

  return (
    <SuspenseWrapper title={t('faq:title')}>
      <PageContent />
    </SuspenseWrapper>
  )
}
function PageContent(): JSX.Element {
  const t = useTranslatable()
  const locale = useLocale()
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('query')
  const page = searchParams.get('page')
  const perPage = searchParams.get('perPage')
  useClearIdParam() // Clear qId on page change

  const {
    data: { data, meta },
  } = useApi({
    endpoint: Api.getFaqQuestions,
    params: React.useMemo(
      () => ({
        headers: {
          'Accept-Language': locale,
        },
        query: {
          page: Number(page) ?? 1,
          search: query,
          perPage: Number(perPage),
        },
      }),
      [locale, page, query, perPage]
    ),
  })
  const pagination = <Pagination pagination={meta.pagination} />

  return (
    <Section title={t('faq:title')} icon={<Courselist />}>
      <Form
        initialValues={{ query: searchParams.get('query') ?? '' }}
        onSubmit={async (value) => setSearchParams((prev) => ({ ...prev, query: value.query }), { replace: true })}
        classNames={{ form: 'mb-2 flex flex-wrap md:!flex-nowrap justify-between' }}
      >
        <div className="w-full">
          <TextInput
            type="text"
            name="query"
            className="w-full"
            placeholder={t('common:enter_your_keyword')}
            searchField
          />
        </div>
        <Button variant="red" className="ml-0 w-[120px] md:ml-4" type="submit">
          {t('common:search')}
        </Button>
      </Form>
      {data.length === 0 ? (
        <NoContent header={t('faq:no_questions')} image={Warning} />
      ) : (
        <>
          {pagination}
          <div className="mt-4 flex flex-col overflow-hidden rounded-card border-DEFAULT border-borderGrey">
            {data.map((item) => (
              <AccordionItem item={item} key={item.id} />
            ))}
          </div>
          {pagination}
        </>
      )}
    </Section>
  )
}

interface Props {
  readonly item: Api.Faq
}
function AccordionItem({ item }: Props): JSX.Element {
  const [element, setElement] = useState<HTMLDivElement | null>(null)
  const theme = useTheme()
  const t = useTranslatable()
  const [searchParams, setSearchParams] = useSearchParams()
  const allSearchParams = useAllSearchParams()
  const qId = searchParams.get('qId')
  const show = qId === item.id && element != null

  const copyToClipboard = React.useCallback(async () => {
    try {
      const link = window.location.origin + window.location.pathname + `/${item.id}`
      await navigator.clipboard.writeText(link)
      toast.success(t('common:link_copied'))
    } catch (error) {
      console.error(error)
    }
  }, [item.id, t])

  return (
    <div className="overflow-hidden border-b border-borderGrey last:border-none">
      <div
        className="flex w-full cursor-pointer items-center justify-between p-4 duration-100 ease-in-out hover:bg-card"
        onClick={() => setSearchParams({ ...allSearchParams, qId: qId === item.id ? '' : item.id })}
      >
        <h2>{item.question}</h2>

        <div className={`mr-2 w-fit duration-300 ease-in-out ${show ? 'rotate-[270deg]' : 'rotate-90'}`}>
          <ArrowRight />
        </div>
      </div>

      <div
        style={show ? { height: `${element.offsetHeight}px`, overflowX: 'auto' } : { height: '0px' }}
        className="bg-card px-5 ease-in-out"
      >
        <div ref={setElement} className="py-4">
          <div className="text-right">
            <span className="mr-4 dark:text-white">
              {' '}
              <span className="opacity-100">ID: </span>
              {item.id}
            </span>
            <button data-tooltip-id={item.id} className="my-anchor-element" onClick={() => void copyToClipboard()}>
              <div
                onClick={void copyToClipboard}
                className={`mb-[10px] flex cursor-pointer items-center duration-150 ease-in-out ${
                  theme === 'dark' ? 'text-white hover:text-veryLightGrey' : 'text-veryLightGrey hover:text-black'
                }`}
              >
                <span className="text-lightPrimaryIconText">
                  <FontAwesomeIcon icon={ProLightSvgIcons.faCopy} />
                </span>
              </div>
            </button>
          </div>

          <Tooltip
            id={item.id}
            variant={theme === 'dark' ? 'dark' : 'light'}
            className="mb-1 p-1"
            globalCloseEvents={{ clickOutsideAnchor: true }}
          >
            {t('common:link_copy_to_clipboard')}
          </Tooltip>
          <div dangerouslySetInnerHTML={{ __html: item.answer }} />
        </div>
      </div>
    </div>
  )
}

function useClearIdParam(): void {
  const [firstload, setFirstload] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const perPage = searchParams.get('perPage')
  const qId = searchParams.get('qId')

  React.useEffect(() => {
    if (firstload) {
      setFirstload(false)
      return
    }
    if (qId != null) {
      searchParams.delete('qId')
      setSearchParams(searchParams, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage])
}
