import { Link } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { BetaSign } from 'src/assets/icons/customIcons/beta_sign'
import LogoIcon from 'src/assets/icons/menu/logo-white.svg'
import { useAuth } from 'src/hooks/auth/authObservableValue'
import { useTranslatable } from 'src/hooks/locale/utils'

interface Props {
  isSidebarOpen?: boolean
}

export default function ArgusLogo({ isSidebarOpen = true }: Props): JSX.Element {
  const { width } = useWindowSize()
  const t = useTranslatable()
  const auth = useAuth()

  return (
    <Link
      to="/"
      className={`ml-[6px] cursor-pointer items-center justify-center duration-[800ms] ease-in-out ${
        isSidebarOpen ? 'flex md:ml-[45px]' : 'block'
      } ${auth.state !== 'Authenticated' ? '!ml-0' : ''}`}
    >
      <img className={`ml-[2px] duration-[800ms] ${isSidebarOpen ? 'w-[60px]' : 'w-[35px]'}`} src={LogoIcon} />
      <div className={`${width < 768 ? 'block' : isSidebarOpen ? 'block' : 'ml-2 mt-2 flex'}`}>
        <div
          className={`overflow-x-hidden text-primaryRed duration-[800ms] ease-in-out ${
            isSidebarOpen ? 'ml-[22px] text-headline opacity-100' : 'ml-0 w-0 text-[5px] opacity-0'
          }`}
        >
          {t('system:argus')}
        </div>
        <div className="flex justify-end">
          <BetaSign />
        </div>
      </div>
    </Link>
  )
}
