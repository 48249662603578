export function BellIcon(): JSX.Element {
  return (
    <svg id="bell_icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_3629" data-name="Rectangle 3629" width="24" height="24" opacity="0" />
      <rect
        id="Rectangle_3630"
        data-name="Rectangle 3630"
        width="22"
        height="22"
        transform="translate(1 1)"
        opacity="0"
      />
      <path
        id="Rectangle_3631"
        data-name="Rectangle 3631"
        d="M6,1A5.006,5.006,0,0,0,1,6v7H11V6A5.006,5.006,0,0,0,6,1M6,0a6,6,0,0,1,6,6v8H0V6A6,6,0,0,1,6,0Z"
        transform="translate(6 5)"
        fill="gray"
      />
      <rect
        id="Rectangle_3632"
        data-name="Rectangle 3632"
        width="16"
        height="1"
        transform="translate(4 18)"
        fill="gray"
      />
      <rect
        id="Rectangle_3633"
        data-name="Rectangle 3633"
        width="3"
        height="2"
        rx="1"
        transform="translate(13 3) rotate(90)"
        fill="gray"
      />
      <path
        id="Path_11"
        data-name="Path 11"
        d="M-256,5a1,1,0,0,0,1-1h-2a1,1,0,0,0,1,1m0,1a2,2,0,0,1-2-2,2,2,0,0,1,.268-1h3.465A2,2,0,0,1-254,4,2,2,0,0,1-256,6Z"
        transform="translate(268 15)"
        fill="gray"
      />
    </svg>
  )
}
