import * as d from 'decoders'
import { useEffect } from 'react'
import * as Api from 'src/api'
import { getMessagingToken } from 'src/helpers/firebase'
import { storageValue, useStorageValue } from 'src/helpers/storage'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'
import { useNotificationsState } from './Notifications'

export type State = null | string

export const FirebaseMessagingTokenStorage = storageValue<State>(
  'FirebaseMessagingToken',
  (value) => JSON.stringify(value),
  (serializedValue) => {
    const decoder = d.nullable(d.string)

    return decoder.verify(JSON.parse(serializedValue))
  }
)

export const useFirebaseMessagingTokenManager = (): void => {
  const token = useStorageValue(FirebaseMessagingTokenStorage)
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const auth = useAuth()
  const permission = useNotificationsState()

  useEffect(() => {
    const fetchToken = async (): Promise<void> => {
      const currentToken = token
      if (auth.state === 'Authenticated') {
        if (currentToken == null && permission === 'granted') {
          const newToken = await getMessagingToken()
          FirebaseMessagingTokenStorage.setValue(newToken)
          void Api.postPushSubscriptions({
            headers,
            body: {
              token: newToken,
            },
          })
        } else if (currentToken != null && permission === 'granted') {
          const newToken = await getMessagingToken()
          if (currentToken !== newToken) {
            FirebaseMessagingTokenStorage.setValue(newToken)
            void Api.deletePushSubscriptions({
              headers: {
                'Accept-Language': locale,
              },
              args: {
                token: currentToken,
              },
            })
            void Api.postPushSubscriptions({
              headers,
              body: {
                token: newToken,
              },
            })
          }
        } else if (currentToken != null) {
          FirebaseMessagingTokenStorage.setValue(null)
          void Api.deletePushSubscriptions({
            headers: {
              'Accept-Language': locale,
            },
            args: {
              token: currentToken,
            },
          })
        }
      } else if (currentToken != null) {
        FirebaseMessagingTokenStorage.setValue(null)
        void Api.deletePushSubscriptions({
          headers: {
            'Accept-Language': locale,
          },
          args: {
            token: currentToken,
          },
        })
      }
    }

    fetchToken().catch(() => {
      console.error('h')
    })
  }, [auth.state, headers, locale, permission, token])
}
