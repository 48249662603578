import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { type UserAnswerOption } from 'src/api'
import { useTheme } from 'src/state/providers/Theme'
import * as SurveyTable from 'src/tailwind/components/SurveyTable'
import * as Yup from 'yup'

interface Option {
  readonly id: number
  readonly title: string // გამოდის ჩეკბოქსის ლეიბლად
  readonly customAllowed: boolean
  readonly fcolor: string
  readonly bcolor: string
}

interface CheckboxType {
  readonly id: number
  readonly type: number
  readonly title: string
  readonly required: boolean
  readonly fcolor: string
  readonly bcolor: string
  readonly options: Option[]
}

interface QuestionTypeCheckboxValue {
  readonly optionId: number
}

interface TypeCheckboxProps {
  readonly question: CheckboxType
  readonly name: string
  readonly index: number
}

export function SurveyCheckboxSchema(message: string, isRequired: boolean): Yup.Schema {
  if (!isRequired) return Yup.mixed()
  return Yup.array().min(1, message).required(message)
}

export default function SurveyCheckboxInput({ question, name, index }: TypeCheckboxProps): React.ReactElement {
  const { errors, isValid, submitCount } = useFormikContext<{ [k: string]: readonly UserAnswerOption[] | undefined }>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<undefined | readonly QuestionTypeCheckboxValue[]>({ name })
  const theme = useTheme()

  const labelPrefix = `label-${React.useId()}`

  const values = React.useRef(
    React.useMemo(() => {
      return Object.fromEntries(
        question.options.map((o) => {
          return [`checkbox-${o.id}`, field.value?.find((v) => v.optionId === o.id) != null]
        })
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  function updateValue(): void {
    void helpers.setValue(
      question.options.flatMap((option) => {
        const value = values.current[`checkbox-${option.id}`]!

        if (value) {
          return {
            optionId: option.id,
          }
        }

        return []
      })
    )
  }

  return (
    <div>
      <div className="flex">
        <div className="mr-1">{index}.</div>
        <div className="font-bold">
          {question.title}
          {question.required && <span className="ml-1 text-primaryRed">*</span>}
        </div>
      </div>
      <SurveyTable.SurveyTable className="border-0">
        <SurveyTable.Thead />
        <SurveyTable.Tbody>
          {question.options.map((option) => (
            <SurveyTable.Tr key={option.id}>
              <SurveyTable.Td>
                <div
                  style={{
                    color: `${option.fcolor ?? (theme === 'dark' ? '#e5e5e5' : 'initial')}`,
                    backgroundColor: `${option.bcolor ?? 'initial'}`,
                  }}
                >
                  <input
                    id={`${labelPrefix}-${option.id}`}
                    name={`${labelPrefix}-${option.id}`}
                    type="checkbox"
                    checked={values.current[`checkbox-${option.id}`]}
                    onChange={(e) => {
                      values.current[`checkbox-${option.id}`] = e.target.checked
                      updateValue()
                    }}
                  />
                  <label htmlFor={`${labelPrefix}-${option.id}`} style={{ marginLeft: 10, marginTop: '1.4px' }}>
                    {option.title}
                  </label>
                </div>
              </SurveyTable.Td>
            </SurveyTable.Tr>
          ))}
        </SurveyTable.Tbody>
      </SurveyTable.SurveyTable>
      <div className="h-[30px] px-[12px] py-0 text-sm text-primaryRed">
        {!isValid && submitCount > 0 ? errors[`field-${question.id}`] : null}
      </div>
    </div>
  )
}
