import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { useLocale } from 'src/hooks/locale/locale'
import { useSidebar } from 'src/hooks/sidebar'
import { SidebarLink } from 'src/views/components/SidebarLink'

interface Page {
  readonly link: string
  readonly title?: string
}

interface Props {
  readonly icon: React.ReactNode
  readonly title: string
  readonly defaultPath?: string
  readonly end?: boolean
  readonly isStudent?: boolean
  readonly pages?: readonly Page[]
}

export default function MenuItem({
  icon,
  title,
  pages = [],
  defaultPath,
  end = false,
  isStudent,
}: Props): React.ReactElement | null {
  const isSidebarOpen = useSidebar()
  const locale = useLocale()

  return (
    <li className="relative">
      <SidebarLink
        end={end}
        to={`/${locale}/${defaultPath ?? pages[0]!.link}`}
        className="peer flex cursor-pointer duration-0 hover:bg-hover-nav"
      >
        <div className="anchor flex h-[42px] items-center justify-start px-5 duration-0">
          <span className="asset-left-space">{icon}</span>
          <span className="link-text ml-2 duration-0">{title}</span>
        </div>
      </SidebarLink>

      {!isSidebarOpen && (
        <div className="invisible absolute left-[55px] top-0 h-fit whitespace-nowrap rounded-DEFAULT border border-primaryTextColor/10 bg-primaryWhite shadow-2xl duration-[50ms] ease-in-out hover:!visible peer-hover:!visible sm:hidden xxs:hidden xs:hidden">
          <div
            className={`fixed z-10 w-auto px-4 py-[9px] opacity-100 shadow-2xl ${pages.length === 0 || isStudent ? 'bg-card' : 'bg-primaryWhite'}`}
          >
            <NavLink to={`/${locale}/${defaultPath ?? pages[0]!.link}`} className="font-bold">
              {title}
            </NavLink>
          </div>

          {pages.length > 1 && (
            <ul>
              {pages.map((page, index) => (
                <li key={index}>
                  <NavLink
                    className={({ isActive }) =>
                      `block px-4 py-2 ${isActive ? 'bg-seconderyRed text-white' : 'hover:bg-card hover:!text-black'}`
                    }
                    to={`/${locale}/${page.link}`}
                  >
                    {page.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </li>
  )
}
