export function MessagesIcon(): JSX.Element {
  return (
    <svg id="messages_icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_5" data-name="Rectangle 5" width="24" height="24" fill="#d6d6d6" opacity="0" />
      <rect
        id="Rectangle_10"
        data-name="Rectangle 10"
        width="22"
        height="22"
        transform="translate(1 1)"
        fill="#d6d6d6"
        opacity="0"
      />
      <g
        id="Rectangle_11"
        data-name="Rectangle 11"
        transform="translate(2 4)"
        fill="none"
        stroke="gray"
        strokeWidth="1"
      >
        <rect width="20" height="16" rx="2" stroke="none" />
        <rect x="0.5" y="0.5" width="19" height="15" rx="1.5" fill="none" />
      </g>
      <path
        id="Path_10"
        data-name="Path 10"
        d="M60.537,22.76,51.008,29.1,41.369,22.76"
        transform="translate(-38.953 -16)"
        fill="none"
        stroke="gray"
        strokeWidth="1"
      />
    </svg>
  )
}
