import { useCallback, useMemo, useState } from 'react'
import { useMount } from 'react-use'
import { useStorageValue } from 'src/helpers/storage'
import { getNotificationsState } from './Notifications'
import { notificationsPermissionDenyDateStorage } from './NotificationsPermissionDenyDate'

export interface State {
  readonly handleClose: (granted?: boolean) => void
  readonly isOpen: boolean
}

export function usePermissionModal(): State {
  const [isOpen, setOpen] = useState(false)
  const notificationsPermissionDenyDate = useStorageValue(notificationsPermissionDenyDateStorage)

  const handleClose = useCallback((granted?: boolean): void => {
    if (granted !== true) {
      notificationsPermissionDenyDateStorage.setValue(new Date().getTime())
    }

    setOpen(false)
  }, [])

  const checkDenyDate = useCallback((): void => {
    if (notificationsPermissionDenyDate != null) {
      const currentDate = new Date().getTime()
      const DiffInDays = (currentDate - notificationsPermissionDenyDate) / (1000 * 3600 * 24)
      if (DiffInDays > 7) {
        setTimeout(() => setOpen(true), 1000)
      }
    } else {
      setTimeout(() => setOpen(true), 1000)
    }
  }, [notificationsPermissionDenyDate])

  useMount(() => {
    if (getNotificationsState() === 'granted') {
      notificationsPermissionDenyDateStorage.setValue(null)
    } else {
      checkDenyDate()
    }
  })

  return useMemo(
    () => ({
      isOpen,
      handleClose,
    }),
    [isOpen, handleClose]
  )
}
