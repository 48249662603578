export function GeoIcon(): JSX.Element {
  return (
    <svg
      id="geo_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="clip-path">
          <circle
            id="Ellipse_383"
            data-name="Ellipse 383"
            cx="9"
            cy="9"
            r="9"
            transform="translate(40 735)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Mask_Group_4" data-name="Mask Group 4" transform="translate(-40 -735)" clipPath="url(#clip-path)">
        <circle
          id="Ellipse_386"
          data-name="Ellipse 386"
          cx="9"
          cy="9"
          r="9"
          transform="translate(40 735)"
          fill="#fff"
        />
        <g id="Union_27" data-name="Union 27" transform="translate(40 735)" fill="#de2c2c">
          <path
            d="M 9.499899864196777 18.49990081787109 L 8.500100135803223 18.49990081787109 L 8.500100135803223 9.999899864196777 L 8.500100135803223 9.499899864196777 L 8.000100135803223 9.499899864196777 L -0.4999000132083893 9.499899864196777 L -0.4999000132083893 8.500100135803223 L 8.000100135803223 8.500100135803223 L 8.500100135803223 8.500100135803223 L 8.500100135803223 8.000100135803223 L 8.500100135803223 -0.4999000132083893 L 9.499899864196777 -0.4999000132083893 L 9.499899864196777 8.000100135803223 L 9.499899864196777 8.500100135803223 L 9.999899864196777 8.500100135803223 L 18.49990081787109 8.500100135803223 L 18.49990081787109 9.499899864196777 L 9.999899864196777 9.499899864196777 L 9.499899864196777 9.499899864196777 L 9.499899864196777 9.999899864196777 L 9.499899864196777 18.49990081787109 Z"
            stroke="none"
          />
          <path
            d="M 9.999899864196777 18.99990081787109 L 8.000100135803223 18.99990081787109 L 8.000100135803223 9.999899864196777 L -0.9998999834060669 9.999899864196777 L -0.9998999834060669 8.000100135803223 L 8.000100135803223 8.000100135803223 L 8.000100135803223 -0.9998999834060669 L 9.999899864196777 -0.9998999834060669 L 9.999899864196777 8.000100135803223 L 18.99990081787109 8.000100135803223 L 18.99990081787109 9.999899864196777 L 9.999899864196777 9.999899864196777 L 9.999899864196777 18.99990081787109 Z"
            stroke="none"
            fill="#de2c2c"
          />
        </g>
        <path
          id="Union_28"
          data-name="Union 28"
          d="M-39-731v-1h-1v-2h1v-1h2v1h1v2h-1v1Z"
          transform="translate(82 1472)"
          fill="#de2c2c"
        />
        <path
          id="Union_29"
          data-name="Union 29"
          d="M-39-731v-1h-1v-2h1v-1h2v1h1v2h-1v1Z"
          transform="translate(92 1472)"
          fill="#de2c2c"
        />
        <path
          id="Union_30"
          data-name="Union 30"
          d="M-39-731v-1h-1v-2h1v-1h2v1h1v2h-1v1Z"
          transform="translate(92 1482)"
          fill="#de2c2c"
        />
        <path
          id="Union_31"
          data-name="Union 31"
          d="M-39-731v-1h-1v-2h1v-1h2v1h1v2h-1v1Z"
          transform="translate(82 1482)"
          fill="#de2c2c"
        />
      </g>
      <g id="Ellipse_384" data-name="Ellipse 384" fill="none" stroke="#fff" strokeWidth="1">
        <circle cx="9" cy="9" r="9" stroke="none" />
        <circle cx="9" cy="9" r="8.5" fill="none" />
      </g>
    </svg>
  )
}
