/* eslint-disable react-hooks/exhaustive-deps */
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import * as Api from 'src/api'
import { UploadConfigMap } from 'src/api'
import { ProgramsIcon } from 'src/assets/icons/customIcons/page-icons/Programs'
import { useApi } from 'src/helpers/hooks'
import { type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import FileInput from 'src/views/components/forms/formik/FileInput'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextInput from 'src/views/components/forms/formik/TextInput'
import { type formikValuesType } from '.'

export default function Step2(): React.ReactElement | null {
  const id = `label-${React.useId()}`
  const [cvId] = React.useState(`${id}-${Date.now()}`)
  const formik = useFormikContext<formikValuesType>()
  const locale = useLocale()
  const t = useTranslatable()

  const { data: courses } = useApi({
    endpoint: Api.getRegistrationCourses,
    params: React.useMemo(
      () => ({
        headers: {
          'Accept-Language': locale,
        },
      }),
      [locale]
    ),
  })
  const fileInputChange = React.useCallback(
    (files: readonly FileState[], name: string) => {
      if (files[0]?.remote.file != null && files[0].remote.status === 'DONE') {
        void formik.setFieldValue(name, files[0]?.remote.file.id)
      } else {
        void formik.setFieldValue(name, '')
      }
    },
    [formik]
  )
  useEffect(() => {
    const course = courses.find((course) => course.id === formik.values.step2.exam_course)
    if (course != null) {
      void formik.setFieldValue('step2.faculty', course?.program.faculty?.name ?? '')
      void formik.setFieldValue('step2.program', course?.program.name)
    }
  }, [formik.values.step2.exam_course])
  return (
    <div className="mb-3 flex w-full flex-col">
      <div className="mb-3 mr-0 w-full min-w-[100px] md:!mb-0 md:mr-2 md:w-[49%]">
        <SelectInput
          name="step2.exam_course"
          options={courses != null ? courses.map((s) => ({ label: s.name, value: s.id })) : []}
          label={t('registration:exam_course')}
          required
        />
      </div>
      {formik.values.step2.exam_course.length > 0 && (
        <Section
          icon={<ProgramsIcon />}
          title={t('program:faculty_program')}
          className="my-2 w-full rounded-card bg-card p-6 pt-4"
        >
          <div className="mb-3 flex w-full flex-wrap justify-between">
            <TextInput name="step2.faculty" label={t('program:faculty')} type="text" disabled />
            <TextInput name="step2.program" label={t('program:programme')} type="text" disabled />
          </div>
        </Section>
      )}

      <div className="my-3 flex w-full flex-wrap justify-between">
        <div className="w-[32%] min-w-[100px] sm:w-full">
          <FileInput
            key={cvId}
            config={UploadConfigMap.registration}
            onChange={(files) => fileInputChange(files, 'step2.cv_uid')}
            label={t('registration:attach_cv')}
            instructions={t('registration:attach_cv_instructions')}
            name="step2.cv_uid"
            mediaFileType="registratio"
            required
          />
        </div>
      </div>
    </div>
  )
}
