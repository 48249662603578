export function LogoutIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Subtraction_227"
            data-name="Subtraction 227"
            d="M1844-1824h-16v-16h16v2h-6v12h6v2Z"
            transform="translate(-1527 2402)"
            fill="gray"
            stroke="gray"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g id="logout_icon" transform="translate(-299.999 -561)">
        <rect
          id="Rectangle_161"
          data-name="Rectangle 161"
          width="18"
          height="18"
          transform="translate(299.999 561)"
          fill="gray"
          opacity="0"
        />
        <rect
          id="Rectangle_304"
          data-name="Rectangle 304"
          width="16"
          height="16"
          transform="translate(301 562)"
          fill="gray"
          opacity="0"
        />
        <g id="Mask_Group_51" data-name="Mask Group 51" clipPath="url(#clip-path)">
          <g
            id="Rectangle_4164"
            data-name="Rectangle 4164"
            transform="translate(301 562)"
            fill="none"
            stroke="gray"
            strokeWidth="1"
          >
            <rect width="12" height="16" rx="2" stroke="none" />
            <rect x="0.5" y="0.5" width="11" height="15" rx="1.5" fill="none" />
          </g>
        </g>
        <rect
          id="Rectangle_4166"
          data-name="Rectangle 4166"
          width="1"
          height="6"
          transform="translate(316.293 569.293) rotate(45)"
          fill="gray"
        />
        <rect
          id="Rectangle_4168"
          data-name="Rectangle 4168"
          width="1"
          height="11"
          transform="translate(315.671 569.5) rotate(90)"
          fill="gray"
        />
        <rect
          id="Rectangle_4167"
          data-name="Rectangle 4167"
          width="1"
          height="6"
          transform="translate(317 570) rotate(135)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
