export function ScheduleIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="schedule_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <path
          id="Subtraction_3"
          data-name="Subtraction 3"
          d="M8-408H2a2,2,0,0,1-2-2v-8a2,2,0,0,1,2-2H12a2,2,0,0,1,2,2v4H13v-4a1,1,0,0,0-1-1H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H8v1Z"
          transform="translate(41 439)"
          fill="gray"
        />
        <rect
          id="Rectangle_23"
          data-name="Rectangle 23"
          width="1"
          height="5"
          rx="0.5"
          transform="translate(44 17)"
          fill="gray"
        />
        <rect
          id="Rectangle_24"
          data-name="Rectangle 24"
          width="1"
          height="5"
          rx="0.5"
          transform="translate(51 17)"
          fill="gray"
        />
        <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(48 24)" fill="none" stroke="gray" strokeWidth="1">
          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
          <circle cx="4.5" cy="4.5" r="4" fill="none" />
        </g>
        <path
          id="Path_4"
          data-name="Path 4"
          d="M52.5,26.371v2.469l1.67.756"
          fill="none"
          stroke="gray"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
