export function MoneyIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.093" height="11.533" viewBox="0 0 10.093 11.533">
      <path
        id="Union_24"
        data-name="Union 24"
        d="M4.326,11.533V10.091H5.767v1.441Zm2.883-1.441V8.65H7.93a.721.721,0,0,0,.72-.721v-.72h1.441v.72h0A2.163,2.163,0,0,1,7.93,10.091Zm-4.324,0H2.163A2.163,2.163,0,0,1,0,7.928H1.443a.721.721,0,0,0,.72.721H7.208v1.441ZM8.65,7.208a.721.721,0,0,0-.72-.721H7.208V5.046H7.93a2.163,2.163,0,0,1,2.163,2.163ZM2.884,6.487H2.163A2.163,2.163,0,0,1,0,4.324V3.6H1.441v.72h0a.721.721,0,0,0,.72.721H7.208V6.487ZM8.65,3.6a.721.721,0,0,0-.72-.721H7.208V1.441H7.93A2.163,2.163,0,0,1,10.093,3.6ZM0,3.6A2.163,2.163,0,0,1,2.163,1.441H4.326V0H5.767V1.441H7.208V2.883H2.163a.721.721,0,0,0-.72.721Z"
        fill="#fff"
      />
    </svg>
  )
}
