import type * as questionsPage from '../../hooks/questionsPage'
import { useQuestionsPage } from '../../hooks/questionsPage'
import { EndPage } from './EndPage'
import { Questions } from './Questions'
import { StartPage } from './StartPage'

interface Props extends questionsPage.Params {}

export const QuestionsPage = ({ survey, defaultPage, setPage }: Props): JSX.Element | null => {
  const state = useQuestionsPage({
    survey,
    defaultPage,
    setPage,
  })

  if (state.data == null) {
    if (state.page === 1) {
      return <StartPage isLoading survey={survey} setPage={setPage} />
    } else {
      return <EndPage isLoading survey={survey} setPage={setPage} onFinish={async () => void null} />
    }
  }

  return (
    <Questions
      key={state.page}
      perPage={survey.perPage}
      progress={state.progress}
      data={state.data}
      onSubmit={state.onSubmit}
      page={state.page}
      updatePage={state.updatePage}
      isLoading={state.isLoading}
    />
  )
}
