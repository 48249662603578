export function LogIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="log_icon" transform="translate(-299.999 -561)">
        <rect
          id="Rectangle_161"
          data-name="Rectangle 161"
          width="18"
          height="18"
          transform="translate(299.999 561)"
          fill="gray"
          opacity="0"
        />
        <rect
          id="Rectangle_304"
          data-name="Rectangle 304"
          width="16"
          height="16"
          transform="translate(301 562)"
          fill="gray"
          opacity="0"
        />
        <rect
          id="Rectangle_4169"
          data-name="Rectangle 4169"
          width="12"
          height="1"
          rx="0.5"
          transform="translate(301 566)"
          fill="gray"
        />
        <rect
          id="Rectangle_4172"
          data-name="Rectangle 4172"
          width="12"
          height="1"
          rx="0.5"
          transform="translate(301 570)"
          fill="gray"
        />
        <rect
          id="Rectangle_4174"
          data-name="Rectangle 4174"
          width="12"
          height="1"
          rx="0.5"
          transform="translate(301 574)"
          fill="gray"
        />
        <rect
          id="Rectangle_4170"
          data-name="Rectangle 4170"
          width="3"
          height="1"
          rx="0.5"
          transform="translate(314 566)"
          fill="gray"
        />
        <rect
          id="Rectangle_4171"
          data-name="Rectangle 4171"
          width="3"
          height="1"
          rx="0.5"
          transform="translate(314 570)"
          fill="gray"
        />
        <rect
          id="Rectangle_4173"
          data-name="Rectangle 4173"
          width="3"
          height="1"
          rx="0.5"
          transform="translate(314 574)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
