export function LiblaryIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10.605" viewBox="0 0 13 10.605">
      <g id="Group_74" data-name="Group 74" transform="translate(0 -0.929)">
        <g
          id="Rectangle_3709"
          data-name="Rectangle 3709"
          transform="translate(6 2.533)"
          fill="#85bddb"
          stroke="#fff"
          strokeWidth="1"
        >
          <path d="M1,0H6A1,1,0,0,1,7,1V8A1,1,0,0,1,6,9H0A0,0,0,0,1,0,9V1A1,1,0,0,1,1,0Z" stroke="none" />
          <path d="M1,.5H6a.5.5,0,0,1,.5.5V8a.5.5,0,0,1-.5.5H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none" />
        </g>
        <g
          id="Rectangle_3711"
          data-name="Rectangle 3711"
          transform="translate(0 2.533)"
          fill="#85bddb"
          stroke="#fff"
          strokeWidth="1"
        >
          <path d="M1,0H6A1,1,0,0,1,7,1V9A0,0,0,0,1,7,9H1A1,1,0,0,1,0,8V1A1,1,0,0,1,1,0Z" stroke="none" />
          <path d="M1,.5H6a.5.5,0,0,1,.5.5V8.5a0,0,0,0,1,0,0H1A.5.5,0,0,1,.5,8V1A.5.5,0,0,1,1,.5Z" fill="none" />
        </g>
        <g id="Path_22449" data-name="Path 22449" transform="translate(6 4.906)" fill="#85bddb">
          <path
            d="M 0.5000014305114746 6.102719306945801 L 0.5000014305114746 -1.571740388870239 C 0.5020114183425903 -1.600560426712036 0.5588914752006531 -1.697560429573059 0.6876814365386963 -1.76287043094635 C 0.9132462739944458 -1.877249598503113 3.791934728622437 -3.102357149124146 4.576921463012695 -3.434479713439941 L 4.576921463012695 4.312289714813232 C 4.576921463012695 4.327759742736816 4.576921463012695 4.374839782714844 4.409041404724121 4.450899600982666 C 4.248781681060791 4.523499488830566 3.242811441421509 4.946349620819092 2.355251550674438 5.319419384002686 C 1.383556127548218 5.727864265441895 0.7372747659683228 5.999775886535645 0.5000014305114746 6.102719306945801 Z"
            stroke="none"
          />
          <path
            d="M 4.076921463012695 -2.679032802581787 C 3.046180009841919 -2.240645885467529 1.425674438476562 -1.54863977432251 1.000001430511475 -1.356788635253906 L 1.000001430511475 5.347197532653809 C 1.307594776153564 5.217477798461914 1.697679042816162 5.053449630737305 2.161501407623291 4.858489513397217 C 2.911545753479004 4.543214797973633 3.746427059173584 4.192288398742676 4.076921463012695 4.050297737121582 L 4.076921463012695 -2.679032802581787 M 4.661410808563232 -3.977656841278076 C 4.905293941497803 -3.977656841278076 5.076921463012695 -3.744570732116699 5.076921463012695 -3.542900562286377 L 5.076921463012695 4.312289714813232 C 5.076921463012695 4.557889461517334 4.942071437835693 4.758329391479492 4.615381240844727 4.906339645385742 C 4.288691520690918 5.054339408874512 0.9036116600036621 6.466939926147461 0.6295714378356934 6.592009544372559 C 0.355532169342041 6.717079639434814 1.430511474609375e-06 6.486020088195801 1.430511474609375e-06 6.240429878234863 L 1.430511474609375e-06 -1.57174015045166 C 1.430511474609375e-06 -1.81734037399292 0.1875014305114746 -2.069840431213379 0.4615416526794434 -2.208810329437256 C 0.7355813980102539 -2.347770214080811 4.163231372833252 -3.802770614624023 4.492791652679443 -3.9417405128479 C 4.551727771759033 -3.966590881347656 4.608298301696777 -3.977656841278076 4.661410808563232 -3.977656841278076 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
        <g id="Path_22451" data-name="Path 22451" transform="translate(1.923 4.906)" fill="#85bddb">
          <path
            d="M 4.576921463012695 6.102718353271484 C 4.339718341827393 5.999808311462402 3.693675518035889 5.727992057800293 2.722311496734619 5.319686889648438 C 1.834481477737427 4.946496963500977 0.8281814455986023 4.523516654968262 0.6678814291954041 4.450886726379395 C 0.5000014305114746 4.374836921691895 0.5000014305114746 4.327756881713867 0.5000014305114746 4.312286853790283 L 0.5000014305114746 -3.4344801902771 C 1.285717248916626 -3.102029800415039 4.164496421813965 -1.876837015151978 4.389241218566895 -1.762863278388977 C 4.518041610717773 -1.697553277015686 4.574911594390869 -1.600563287734985 4.576931476593018 -1.571123242378235 L 4.576921463012695 6.102718353271484 Z"
            stroke="none"
          />
          <path
            d="M 1.000001430511475 -2.679024219512939 L 1.000001430511475 4.050293445587158 C 1.33050799369812 4.192290306091309 2.165377140045166 4.543211460113525 2.915421485900879 4.858486175537109 C 3.379243850708008 5.053446292877197 3.769325256347656 5.217473030090332 4.076921463012695 5.347194194793701 L 4.076921463012695 -1.356690406799316 C 3.646650791168213 -1.550562381744385 2.02919602394104 -2.241288185119629 1.000001430511475 -2.679024219512939 M 0.4155035018920898 -3.977654457092285 C 0.4686160087585449 -3.977648735046387 0.5251951217651367 -3.966586112976074 0.5841312408447266 -3.941733837127686 C 0.913691520690918 -3.802763938903809 4.341341495513916 -2.347773551940918 4.615381240844727 -2.208803653717041 C 4.889421463012695 -2.069843769073486 5.076921463012695 -1.817343711853027 5.076921463012695 -1.571743488311768 L 5.076921463012695 6.240416526794434 C 5.076921463012695 6.486016273498535 4.721391201019287 6.717076778411865 4.447351455688477 6.592006206512451 C 4.173311233520508 6.466936111450195 0.788231372833252 5.054336547851562 0.4615416526794434 4.906336307525635 C 0.1348514556884766 4.758326530456543 1.430511474609375e-06 4.557886123657227 1.430511474609375e-06 4.312286376953125 L 1.430511474609375e-06 -3.542893886566162 C 1.430511474609375e-06 -3.744572162628174 0.1716208457946777 -3.977680683135986 0.4155035018920898 -3.977654457092285 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}
