import { useTranslatable } from 'src/hooks/locale/utils'
import Checkbox from 'src/views/components/forms/formik/Checkbox'

export default function Step3(): JSX.Element {
  const t = useTranslatable()

  return (
    <div>
      <p className="mb-3 text-section">{t('registration:masters_registration_agreement')}</p>
      <Checkbox name="step3.agreement" label={t('common:agree')} required />
    </div>
  )
}
