import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { type UserAnswerOption } from 'src/api'
import * as SurveyTable from 'src/tailwind/components/SurveyTable'
import * as Yup from 'yup'

interface QuestionTypeText {
  readonly id: number
  readonly type: number
  readonly title: string
  readonly required: boolean
  readonly fcolor?: string
  readonly bcolor?: string
  readonly options: [
    {
      readonly id: number
      readonly title: string
      readonly fcolor?: string
      readonly bcolor?: string
    },
  ]
}

type QuestionTypeTextValue = [
  {
    readonly optionId: number
    readonly value: string
  },
]

interface TypeTextProps {
  readonly question: QuestionTypeText
  readonly name: string
  readonly index: number
}

export function SurveyStringSchema(message: string, isRequired: boolean): Yup.Schema {
  if (!isRequired) return Yup.mixed()

  return Yup.array(Yup.object({ value: Yup.string().required(message) }))
    .min(1, message)
    .required(message)
}

export default function SurveyTextInput({ question, name, index }: TypeTextProps): React.ReactElement {
  const { errors, isValid, submitCount } = useFormikContext<{ [k: string]: readonly UserAnswerOption[] | undefined }>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<undefined | QuestionTypeTextValue>({ name })

  return (
    <div>
      <div className="flex">
        <div className="mr-1">{index}.</div>
        <div className="font-bold">
          {question.title}
          {question.required && <span className="ml-1 text-primaryRed">*</span>}
        </div>
      </div>
      <SurveyTable.SurveyTable className="border-0">
        <SurveyTable.Thead />
        <SurveyTable.Tbody>
          <SurveyTable.Tr>
            <SurveyTable.Td>
              <div>
                <textarea
                  id="text"
                  name="text"
                  className="mb-1 block h-40 w-full rounded-[12px] border border-borderGrey bg-primaryWhite p-2.5 focus:border-blue-500"
                  placeholder={question.options[0].title}
                  value={field.value?.[0].value ?? ''}
                  onChange={(value) => {
                    void helpers.setValue([
                      {
                        optionId: question.options[0].id,
                        value: value.currentTarget.value,
                      },
                    ])
                  }}
                />
              </div>
            </SurveyTable.Td>
          </SurveyTable.Tr>
        </SurveyTable.Tbody>
      </SurveyTable.SurveyTable>
      <div className="h-[30px] px-[12px] py-0 text-sm text-primaryRed">
        {!Array.isArray(errors[`field-${question.id}`]) && submitCount > 0 && !isValid
          ? errors[`field-${question.id}`]
          : Array.isArray(errors[`field-${question.id}`]) && submitCount > 0 && !isValid
            ? (errors[`field-${question.id}`] as any)[0].value
            : null}
      </div>
    </div>
  )
}
