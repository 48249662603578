import * as d from 'decoders'
import { storageValue } from 'src/helpers/storage'

export type State = number | null

export const notificationsPermissionDenyDateStorage = storageValue<State>(
  'NotificationsPermissionDenyDate',
  (value) => JSON.stringify(value),
  (serializedValue) => {
    return d.number.verify(JSON.parse(serializedValue))
  }
)
